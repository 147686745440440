import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type AssignTenantDeviceInput = {
  sharedDeviceId: Scalars['String']['input'];
};

export type CaregiverFilterInput = {
  and?: InputMaybe<CaregiverFilterInput>;
  email?: InputMaybe<FilterExpression>;
  or?: InputMaybe<CaregiverFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
};

export type Condition = {
  __typename?: 'Condition';
  acronym: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  measurements?: Maybe<Array<Measurement>>;
  name: Scalars['String']['output'];
  symptoms?: Maybe<Array<Symptom>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ConditionFilterInput = {
  acronym?: InputMaybe<FilterExpression>;
  and?: InputMaybe<ConditionFilterInput>;
  id?: InputMaybe<FilterExpression>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<ConditionFilterInput>;
};

export type Content = {
  __typename?: 'Content';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  tags?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['String']['output'];
};

export type ContentFilterInput = {
  and?: InputMaybe<ContentFilterInput>;
  or?: InputMaybe<ContentFilterInput>;
  title?: InputMaybe<FilterExpression>;
  type?: InputMaybe<FilterExpression>;
  value?: InputMaybe<FilterExpression>;
};

export enum ContentType {
  Audio = 'Audio',
  Text = 'Text',
  Url = 'URL',
  Video = 'Video'
}

export type CreateCaregiverInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  relation: RelationType;
};

export type CreateConditionInput = {
  acronym: Scalars['String']['input'];
  description: Scalars['String']['input'];
  measurementIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  symptomIds: Array<Scalars['String']['input']>;
};

export type CreateContentInput = {
  tags?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: ContentType;
  value: Scalars['String']['input'];
};

export type CreateDeviceInput = {
  category: DeviceCategory;
  displayName: Scalars['String']['input'];
  managedBy: ManagedBy;
  manufacturer: Scalars['String']['input'];
  measurementIds: Array<Scalars['String']['input']>;
  model: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: DeviceType;
};

export type CreateDocumentationInput = {
  capturedAt: Scalars['DateTime']['input'];
  capturedById: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  note: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  reviewedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewedById?: InputMaybe<Scalars['String']['input']>;
  status: DocumentationStatusType;
};

export type CreateInvitedUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender: PatientGender;
  height?: InputMaybe<Scalars['Float']['input']>;
  invitedByUserId?: InputMaybe<Scalars['String']['input']>;
  isSignUpAllowed: Scalars['Boolean']['input'];
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMeasurementInput = {
  bucket: MeasurementBucketType;
  dataPrecision: Scalars['Float']['input'];
  defaultTarget?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  displayType: MeasurementDisplayType;
  indicator?: InputMaybe<Scalars['String']['input']>;
  maxValue?: InputMaybe<Scalars['Float']['input']>;
  minValue?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  tags?: InputMaybe<Scalars['String']['input']>;
  type: MeasurementType;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessageInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  fromId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  toId: Scalars['String']['input'];
};

export type CreateNotificationInput = {
  body: Scalars['String']['input'];
  notificationType: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreatePatientConditionInput = {
  conditionId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};

export type CreatePatientDeviceInput = {
  deviceId: Scalars['String']['input'];
  deviceInfo?: InputMaybe<Scalars['JSON']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};

export type CreatePatientInput = {
  birthDate: Scalars['DateTime']['input'];
  data: Scalars['JSON']['input'];
  gender: PatientGender;
  height: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export type CreatePatientMeasurementInput = {
  alertAbove?: InputMaybe<Scalars['Float']['input']>;
  alertBelow?: InputMaybe<Scalars['Float']['input']>;
  measurementId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePatientPrescribedDeviceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  deviceStatus: PatientDeviceStatus;
  patientId: Scalars['String']['input'];
  status: DeviceStatus;
};

export type CreatePatientSymptomInput = {
  data: Scalars['JSON']['input'];
  occuredAt: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  symptomId: Scalars['String']['input'];
};

export type CreateSharedDeviceInput = {
  data: Scalars['JSON']['input'];
  deviceId: Scalars['String']['input'];
  hardwareId: Scalars['String']['input'];
  label: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSymptomInput = {
  displayName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateSystemNotificationInput = {
  body: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTaskInput = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  completedById?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  status: StatusType;
  type: Scalars['String']['input'];
};

export type CreateTimeTrackingInput = {
  doctorId: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  patientId: Scalars['String']['input'];
};

export type CreateUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isConfirmed: Scalars['Boolean']['input'];
  isEnabled: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  medicareId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
  roleId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Device = {
  __typename?: 'Device';
  category: DeviceCategory;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  managedBy: ManagedBy;
  manufacturer: Scalars['String']['output'];
  measurements: Array<Measurement>;
  model: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: DeviceType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DeviceCategory {
  Health = 'Health',
  Medical = 'Medical'
}

export type DeviceFilterInput = {
  and?: InputMaybe<DeviceFilterInput>;
  category?: InputMaybe<FilterExpression>;
  displayName?: InputMaybe<FilterExpression>;
  managedBy?: InputMaybe<FilterExpression>;
  manufacturer?: InputMaybe<FilterExpression>;
  model?: InputMaybe<FilterExpression>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<DeviceFilterInput>;
  type?: InputMaybe<FilterExpression>;
};

export enum DeviceStatus {
  Ordered = 'Ordered',
  Prescribed = 'Prescribed',
  Received = 'Received',
  Shipped = 'Shipped'
}

export enum DeviceType {
  ActivityTracker = 'ActivityTracker',
  BpMonitor = 'BPMonitor',
  Cgm = 'CGM',
  Glucometer = 'Glucometer',
  HealthService = 'HealthService',
  HeartRateMonitor = 'HeartRateMonitor',
  PulseOximeter = 'PulseOximeter',
  SleepMonitor = 'SleepMonitor',
  Thermometer = 'Thermometer',
  WeightingScale = 'WeightingScale'
}

export type Documentation = {
  __typename?: 'Documentation';
  capturedAt?: Maybe<Scalars['DateTime']['output']>;
  capturedBy?: Maybe<User>;
  capturedById?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  patient?: Maybe<User>;
  patientId: Scalars['String']['output'];
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<User>;
  reviewedById?: Maybe<Scalars['String']['output']>;
  status: DocumentationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentationFilterInput = {
  and?: InputMaybe<DocumentationFilterInput>;
  capturedAt?: InputMaybe<FilterExpression>;
  capturedById?: InputMaybe<FilterExpression>;
  note?: InputMaybe<FilterExpression>;
  or?: InputMaybe<DocumentationFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
  reviewedAt?: InputMaybe<FilterExpression>;
  reviewedById?: InputMaybe<FilterExpression>;
  status?: InputMaybe<FilterExpression>;
};

export enum DocumentationStatusType {
  Accept = 'Accept',
  Draft = 'Draft',
  Reject = 'Reject'
}

export type Duration = {
  __typename?: 'Duration';
  totalDuration: Scalars['Int']['output'];
};

export type ExternalPatientCondition = {
  __typename?: 'ExternalPatientCondition';
  classificationDescription?: Maybe<Scalars['String']['output']>;
  clinicalStatus: Scalars['String']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  conditionCode: Scalars['JSON']['output'];
  conditionId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  icd10: Scalars['String']['output'];
  icd10Description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  onsetDate: Scalars['DateTime']['output'];
  patientId: Scalars['String']['output'];
  principalDiagnosis: Scalars['Boolean']['output'];
  rankDescription: Scalars['String']['output'];
  revisionBy: Scalars['String']['output'];
  revisionDate: Scalars['DateTime']['output'];
  therapy: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ExternalPatientConditionFilterInput = {
  and?: InputMaybe<ExternalPatientConditionFilterInput>;
  or?: InputMaybe<ExternalPatientConditionFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
};

export type FilterExpression = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InvitedUser = {
  __typename?: 'InvitedUser';
  address?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: PatientGender;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  invitedByUserId?: Maybe<Scalars['String']['output']>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<RelationType>;
  role: UserRole;
  roleId: Scalars['String']['output'];
  roles: ROle;
  state?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type InvitedUserFilterInput = {
  address?: InputMaybe<FilterExpression>;
  and?: InputMaybe<InvitedUserFilterInput>;
  birthDate?: InputMaybe<FilterExpression>;
  city?: InputMaybe<FilterExpression>;
  country?: InputMaybe<FilterExpression>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<FilterExpression>;
  firstName?: InputMaybe<FilterExpression>;
  gender?: InputMaybe<FilterExpression>;
  height?: InputMaybe<FilterExpression>;
  invitedByUserId?: InputMaybe<FilterExpression>;
  joinedAt?: InputMaybe<FilterExpression>;
  lastName?: InputMaybe<FilterExpression>;
  or?: InputMaybe<InvitedUserFilterInput>;
  phoneNumber?: InputMaybe<FilterExpression>;
  role?: InputMaybe<FilterExpression>;
  roleId?: InputMaybe<FilterExpression>;
  state?: InputMaybe<FilterExpression>;
  tenantId?: InputMaybe<FilterExpression>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  zipCode?: InputMaybe<FilterExpression>;
};

export type ListInvitedUser = {
  __typename?: 'ListInvitedUser';
  items: Array<InvitedUser>;
  pagination: Scalars['JSON']['output'];
};

export type ListMessages = {
  __typename?: 'ListMessages';
  items: Array<Message>;
  pagination: Scalars['JSON']['output'];
};

export type ListPatient = {
  __typename?: 'ListPatient';
  items: Array<Patient>;
  pagination: Scalars['JSON']['output'];
};

export type ListSharedDevice = {
  __typename?: 'ListSharedDevice';
  items: Array<SharedDevice>;
  pagination: Scalars['JSON']['output'];
};

export type ListTasks = {
  __typename?: 'ListTasks';
  items: Array<Task>;
  pagination: Scalars['JSON']['output'];
};

export enum ManagedBy {
  System = 'System',
  User = 'User'
}

export type Measurement = {
  __typename?: 'Measurement';
  bucket: MeasurementBucketType;
  conditions?: Maybe<Array<Condition>>;
  createdAt: Scalars['DateTime']['output'];
  dataPrecision?: Maybe<Scalars['Float']['output']>;
  defaultTarget?: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  devices?: Maybe<Array<Device>>;
  displayName: Scalars['String']['output'];
  displayType: MeasurementDisplayType;
  id: Scalars['String']['output'];
  indicator?: Maybe<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  tags?: Maybe<Scalars['String']['output']>;
  type: MeasurementType;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum MeasurementBucketType {
  Activity = 'ACTIVITY',
  Vital = 'VITAL'
}

export enum MeasurementDisplayType {
  RedOnBoth = 'RedOnBoth',
  RedOnLeft = 'RedOnLeft',
  RedOnRight = 'RedOnRight'
}

export type MeasurementFilterInput = {
  and?: InputMaybe<MeasurementFilterInput>;
  bucket?: InputMaybe<FilterExpression>;
  description?: InputMaybe<FilterExpression>;
  displayName?: InputMaybe<FilterExpression>;
  displayType?: InputMaybe<FilterExpression>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<MeasurementFilterInput>;
  type?: InputMaybe<FilterExpression>;
};

export enum MeasurementType {
  Activity = 'Activity',
  Bmi = 'BMI',
  BloodPressure = 'BloodPressure',
  Exercise = 'Exercise',
  Glucose = 'Glucose',
  HeartRate = 'HeartRate',
  Height = 'Height',
  LungFunction = 'LungFunction',
  Oxygen = 'Oxygen',
  Pain = 'Pain',
  PulseRate = 'PulseRate',
  Respiratory = 'Respiratory',
  RestingHeartRate = 'RestingHeartRate',
  Sleep = 'Sleep',
  Spirometry = 'Spirometry',
  Steps = 'Steps',
  Temperature = 'Temperature',
  Weight = 'Weight'
}

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<MessageUser>;
  fromId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  to?: Maybe<MessageUser>;
  toId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MessageFilterInput = {
  and?: InputMaybe<Array<MessageFilterInput>>;
  createdAt?: InputMaybe<FilterExpression>;
  fromId?: InputMaybe<FilterExpression>;
  or?: InputMaybe<Array<MessageFilterInput>>;
  patientId?: InputMaybe<FilterExpression>;
  toId?: InputMaybe<FilterExpression>;
  updatedAt?: InputMaybe<FilterExpression>;
};

export type MessageUser = {
  __typename?: 'MessageUser';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  assignTenantAppDevice: SharedDevice;
  createCaregiver: InvitedUser;
  createCondition: Condition;
  createContent: Content;
  createDevice: Device;
  createDocumentation: Documentation;
  createInvitedUsers: InvitedUser;
  createMeasurement: Measurement;
  createMessage: Message;
  createNotification: Notification;
  createPatient: Patient;
  createPatientCondition: PatientCondition;
  createPatientDevice: PatientDevice;
  createPatientMeasurement: PatientMeasurement;
  createPatientPrescribedDevice: PatientPrescribedDevice;
  createPatientSymptom: PatientSymptom;
  createSharedDevice: SharedDevice;
  createSymptom: Symptom;
  createSystemNotification: SystemNotification;
  createTask: Task;
  createTimeTracking: TimeTracking;
  createUsers: User;
  deleteCaregiver: InvitedUser;
  deleteCondition: Condition;
  deleteContent: Content;
  deleteDevice: Device;
  deleteDocumentation: Documentation;
  deleteInvitedUsers: InvitedUser;
  deleteMeasurements: Measurement;
  deleteMessage: Message;
  deleteNotification: Notification;
  deletePatient: Patient;
  deletePatientCondition: PatientCondition;
  deletePatientDevices: PatientDevice;
  deletePatientMeasurements: PatientMeasurement;
  deletePatientPrescribedDevices: PatientPrescribedDevice;
  deletePatientSymptoms: PatientSymptom;
  deleteSharedDevice: SharedDevice;
  deleteSymptom: Symptom;
  deleteSystemNotification: SystemNotification;
  deleteTask: Task;
  deleteUsers: User;
  switchPatient: TenantAppDevicePatient;
  updateCaregiver: InvitedUser;
  updateCondition: Condition;
  updateContent: Content;
  updateDevice: Device;
  updateDeviceStatus: PatientPrescribedDevice;
  updateDocumentation: Documentation;
  updateInvitedUser: InvitedUser;
  updateMeasurement: Measurement;
  updateMessage: Message;
  updateNotification: Notification;
  updatePatient: Patient;
  updatePatientCondition: PatientCondition;
  updatePatientDevice: PatientDevice;
  updatePatientMeasurement: PatientMeasurement;
  updatePatientPrescribedDevice: PatientPrescribedDevice;
  updatePatientSymptom: PatientSymptom;
  updateSharedDevice: SharedDevice;
  updateSymptom: Symptom;
  updateSystemNotification: SystemNotification;
  updateTask: Task;
  updateUser: User;
};


export type MutationAssignTenantAppDeviceArgs = {
  input: AssignTenantDeviceInput;
};


export type MutationCreateCaregiverArgs = {
  input: CreateCaregiverInput;
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationCreateContentArgs = {
  input: CreateContentInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateDocumentationArgs = {
  input: CreateDocumentationInput;
};


export type MutationCreateInvitedUsersArgs = {
  input: CreateInvitedUserInput;
};


export type MutationCreateMeasurementArgs = {
  input: CreateMeasurementInput;
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreatePatientArgs = {
  input: CreatePatientInput;
};


export type MutationCreatePatientConditionArgs = {
  input: CreatePatientConditionInput;
};


export type MutationCreatePatientDeviceArgs = {
  input: CreatePatientDeviceInput;
};


export type MutationCreatePatientMeasurementArgs = {
  input: CreatePatientMeasurementInput;
};


export type MutationCreatePatientPrescribedDeviceArgs = {
  input: CreatePatientPrescribedDeviceInput;
};


export type MutationCreatePatientSymptomArgs = {
  input: CreatePatientSymptomInput;
};


export type MutationCreateSharedDeviceArgs = {
  input: CreateSharedDeviceInput;
};


export type MutationCreateSymptomArgs = {
  input: CreateSymptomInput;
};


export type MutationCreateSystemNotificationArgs = {
  input: CreateSystemNotificationInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTimeTrackingArgs = {
  input: CreateTimeTrackingInput;
};


export type MutationCreateUsersArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCaregiverArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteConditionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteContentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDocumentationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteInvitedUsersArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMeasurementsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientConditionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientDevicesArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientMeasurementsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientPrescribedDevicesArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientSymptomsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSharedDeviceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSymptomArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSystemNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUsersArgs = {
  id: Scalars['String']['input'];
};


export type MutationSwitchPatientArgs = {
  input: SwitchPatientInput;
};


export type MutationUpdateCaregiverArgs = {
  id: Scalars['String']['input'];
  input: UpdateCareGiverInput;
};


export type MutationUpdateConditionArgs = {
  id: Scalars['String']['input'];
  input: UpdateConditionInput;
};


export type MutationUpdateContentArgs = {
  id: Scalars['String']['input'];
  input: UpdateContentInput;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['String']['input'];
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceStatusArgs = {
  deviceId: Scalars['String']['input'];
  input: UpdatePatientPrescribedDeviceInput;
};


export type MutationUpdateDocumentationArgs = {
  id: Scalars['String']['input'];
  input: UpdateDocumentationInput;
};


export type MutationUpdateInvitedUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateInvitedUserInput;
};


export type MutationUpdateMeasurementArgs = {
  id: Scalars['String']['input'];
  input: UpdateMeasurementInput;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['String']['input'];
  input: UpdateMessageInput;
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['String']['input'];
  input: UpdateNotificationInput;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientInput;
};


export type MutationUpdatePatientConditionArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientConditionInput;
};


export type MutationUpdatePatientDeviceArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientDeviceInput;
};


export type MutationUpdatePatientMeasurementArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientMeasurementInput;
};


export type MutationUpdatePatientPrescribedDeviceArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientPrescribedDeviceInput;
};


export type MutationUpdatePatientSymptomArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientSymptomInput;
};


export type MutationUpdateSharedDeviceArgs = {
  id: Scalars['String']['input'];
  input: UpdateSharedDeviceInput;
};


export type MutationUpdateSymptomArgs = {
  id: Scalars['String']['input'];
  input: UpdateSymptomInput;
};


export type MutationUpdateSystemNotificationArgs = {
  id: Scalars['String']['input'];
  input: UpdateSystemNotificationInput;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['String']['input'];
  input: UpdateTaskInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  body?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Content>;
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorText?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationType: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  retryAttempt?: Maybe<Scalars['Float']['output']>;
  sendStatus?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type NotificationFilterInput = {
  and?: InputMaybe<NotificationFilterInput>;
  body?: InputMaybe<FilterExpression>;
  contentId?: InputMaybe<FilterExpression>;
  isRead?: InputMaybe<FilterExpression>;
  notificationType?: InputMaybe<FilterExpression>;
  or?: InputMaybe<NotificationFilterInput>;
  title?: InputMaybe<FilterExpression>;
  userId?: InputMaybe<FilterExpression>;
};

export type Orders = {
  __typename?: 'Orders';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  orderId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PaginationInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type Patient = {
  __typename?: 'Patient';
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  conditions?: Maybe<Array<Condition>>;
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  gender: PatientGender;
  healthDataUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  weight: Scalars['Float']['output'];
};

export type PatientCondition = {
  __typename?: 'PatientCondition';
  condition: Condition;
  conditionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PatientConditionFilterInput = {
  and?: InputMaybe<PatientConditionFilterInput>;
  conditionId?: InputMaybe<FilterExpression>;
  or?: InputMaybe<PatientConditionFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
};

export type PatientDevice = {
  __typename?: 'PatientDevice';
  createdAt: Scalars['DateTime']['output'];
  device: Device;
  deviceId: Scalars['String']['output'];
  deviceInfo?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  isConnected: Scalars['Boolean']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PatientDeviceFilterInput = {
  and?: InputMaybe<PatientDeviceFilterInput>;
  deviceId?: InputMaybe<FilterExpression>;
  deviceInfo?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<PatientDeviceFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
};

export enum PatientDeviceStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type PatientFilterInput = {
  and?: InputMaybe<PatientFilterInput>;
  birthDate?: InputMaybe<FilterExpression>;
  data?: InputMaybe<FilterExpression>;
  gender?: InputMaybe<FilterExpression>;
  height?: InputMaybe<FilterExpression>;
  or?: InputMaybe<PatientFilterInput>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export enum PatientGender {
  Female = 'female',
  Male = 'male',
  Na = 'na',
  Other = 'other'
}

export type PatientMeasurement = {
  __typename?: 'PatientMeasurement';
  alertAbove?: Maybe<Scalars['Float']['output']>;
  alertBelow?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  measurement: Measurement;
  measurementId: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  targetValue?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PatientMeasurementFilterInput = {
  alertAbove?: InputMaybe<FilterExpression>;
  alertBelow?: InputMaybe<FilterExpression>;
  and?: InputMaybe<PatientMeasurementFilterInput>;
  measurementId?: InputMaybe<FilterExpression>;
  or?: InputMaybe<PatientMeasurementFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
  targetValue?: InputMaybe<FilterExpression>;
};

export type PatientPrescribedDevice = {
  __typename?: 'PatientPrescribedDevice';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  device?: Maybe<Device>;
  deviceId: Scalars['String']['output'];
  deviceStatus: PatientDeviceStatus;
  id: Scalars['String']['output'];
  orders: Array<Orders>;
  patient?: Maybe<Patient>;
  patientDevice?: Maybe<PatientDevice>;
  patientId: Scalars['String']['output'];
  status: DeviceStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PatientPrescribedDeviceFilterInput = {
  and?: InputMaybe<PatientPrescribedDeviceFilterInput>;
  comment?: InputMaybe<FilterExpression>;
  deviceId?: InputMaybe<FilterExpression>;
  deviceStatus?: InputMaybe<FilterExpression>;
  or?: InputMaybe<PatientPrescribedDeviceFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
  status?: InputMaybe<FilterExpression>;
};

export type PatientSymptom = {
  __typename?: 'PatientSymptom';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  data: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  occuredAt: Scalars['DateTime']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  severity?: Maybe<Scalars['Float']['output']>;
  severityMax?: Maybe<Scalars['Float']['output']>;
  symptom: Symptom;
  symptomId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PatientSymptomFilterInput = {
  and?: InputMaybe<PatientSymptomFilterInput>;
  createdAt?: InputMaybe<FilterExpression>;
  data?: InputMaybe<FilterExpression>;
  occuredAt?: InputMaybe<FilterExpression>;
  or?: InputMaybe<PatientSymptomFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
  symptomId?: InputMaybe<FilterExpression>;
};

export type Query = {
  __typename?: 'Query';
  getCaregiver: InvitedUser;
  getCondition: Condition;
  getContent: Content;
  getDevice: Device;
  getDocumentation: Documentation;
  getInvitedUser: InvitedUser;
  getMeasurement: Measurement;
  getMessage: Message;
  getPatient: Patient;
  getPatientCondition: PatientCondition;
  getPatientDevice: PatientDevice;
  getPatientMeasurement: PatientMeasurement;
  getPatientPrescribedDevice: PatientPrescribedDevice;
  getPatientSymptom: PatientSymptom;
  getSharedDevice: SharedDevice;
  getSymptom: Symptom;
  getSystemNotification: SystemNotification;
  getTask: Task;
  getTimeTrackingSummary: Duration;
  getUser: User;
  listCaregivers: Array<InvitedUser>;
  listConditions: Array<Condition>;
  listContents: Array<Content>;
  listDevices: Array<Device>;
  listDocumentation: Array<Documentation>;
  listExternalPatientCondition: Array<ExternalPatientCondition>;
  listInvitedUsers: ListInvitedUser;
  listMeasurements: Array<Measurement>;
  listMessages: ListMessages;
  listNotifications: Array<Notification>;
  listPatientCondition: Array<PatientCondition>;
  listPatientDevices: Array<PatientDevice>;
  listPatientMeasurements: Array<PatientMeasurement>;
  listPatientPrescribedDevices: Array<PatientPrescribedDevice>;
  listPatientSymptoms: Array<PatientSymptom>;
  listPatients: ListPatient;
  listRole: Array<ROle>;
  listSharedDevice: ListSharedDevice;
  listSymptoms: Array<Symptom>;
  listSystemNotifications: Array<SystemNotification>;
  listTasks: ListTasks;
  listTemplates: Array<Template>;
  listTenants: Array<Tenant>;
  listUsers: Array<User>;
};


export type QueryGetCaregiverArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetConditionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetContentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDocumentationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInvitedUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMeasurementArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientConditionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientMeasurementArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientPrescribedDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPatientSymptomArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSharedDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSymptomArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSystemNotificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTimeTrackingSummaryArgs = {
  doctorId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryListCaregiversArgs = {
  filter?: InputMaybe<CaregiverFilterInput>;
};


export type QueryListConditionsArgs = {
  filter?: InputMaybe<ConditionFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListContentsArgs = {
  filter?: InputMaybe<ContentFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListDevicesArgs = {
  filter?: InputMaybe<DeviceFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListDocumentationArgs = {
  filter?: InputMaybe<DocumentationFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListExternalPatientConditionArgs = {
  filter?: InputMaybe<ExternalPatientConditionFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListInvitedUsersArgs = {
  filter?: InputMaybe<InvitedUserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<SearchUserInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListMeasurementsArgs = {
  filter?: InputMaybe<MeasurementFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListMessagesArgs = {
  filter?: InputMaybe<MessageFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListNotificationsArgs = {
  filter?: InputMaybe<NotificationFilterInput>;
};


export type QueryListPatientConditionArgs = {
  filter?: InputMaybe<PatientConditionFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListPatientDevicesArgs = {
  filter?: InputMaybe<PatientDeviceFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListPatientMeasurementsArgs = {
  filter?: InputMaybe<PatientMeasurementFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListPatientPrescribedDevicesArgs = {
  filter?: InputMaybe<PatientPrescribedDeviceFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListPatientSymptomsArgs = {
  filter?: InputMaybe<PatientSymptomFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListPatientsArgs = {
  filter?: InputMaybe<PatientFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<SearchInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListRoleArgs = {
  filter?: InputMaybe<RoleFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListSharedDeviceArgs = {
  filter?: InputMaybe<SharedDeviceFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryListSymptomsArgs = {
  filter?: InputMaybe<SymptomFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListSystemNotificationsArgs = {
  filter?: InputMaybe<SystemNotificationFilterInput>;
};


export type QueryListTasksArgs = {
  filter?: InputMaybe<TaskFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<SearchTaskInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListTemplatesArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListTenantsArgs = {
  filter?: InputMaybe<TenantFilterInput>;
  sort?: InputMaybe<Array<SortOrder>>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
};

export type ROle = {
  __typename?: 'ROle';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum RelationType {
  Brother = 'Brother',
  Father = 'Father',
  Husband = 'Husband',
  Mother = 'Mother',
  Other = 'Other',
  Sister = 'Sister',
  Wife = 'Wife'
}

export type RoleFilterInput = {
  and?: InputMaybe<RoleFilterInput>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<RoleFilterInput>;
};

export type SearchInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type SearchTaskInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type SearchUserInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type SharedDevice = {
  __typename?: 'SharedDevice';
  appDevice?: Maybe<TenantAppDevice>;
  createdAt: Scalars['DateTime']['output'];
  currentAppDeviceId?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  device?: Maybe<Device>;
  deviceId: Scalars['String']['output'];
  hardwareId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SharedDeviceFilterInput = {
  and?: InputMaybe<SharedDeviceFilterInput>;
  currentAppDeviceId?: InputMaybe<FilterExpression>;
  deviceId?: InputMaybe<FilterExpression>;
  label?: InputMaybe<FilterExpression>;
  or?: InputMaybe<SharedDeviceFilterInput>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortOrder = {
  direction: SortDirection;
  field: Scalars['String']['input'];
};

export enum StatusType {
  Completed = 'Completed',
  Pending = 'Pending'
}

export type SwitchPatientInput = {
  patientId: Scalars['String']['input'];
};

export type Symptom = {
  __typename?: 'Symptom';
  conditions?: Maybe<Array<Condition>>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SymptomFilterInput = {
  and?: InputMaybe<SymptomFilterInput>;
  displayName?: InputMaybe<FilterExpression>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<SymptomFilterInput>;
};

export type SystemNotification = {
  __typename?: 'SystemNotification';
  body: Scalars['String']['output'];
  contentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SystemNotificationFilterInput = {
  and?: InputMaybe<SystemNotificationFilterInput>;
  body?: InputMaybe<FilterExpression>;
  contentId?: InputMaybe<FilterExpression>;
  or?: InputMaybe<SystemNotificationFilterInput>;
  title?: InputMaybe<FilterExpression>;
};

export type Task = {
  __typename?: 'Task';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedById?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type TaskFilterInput = {
  and?: InputMaybe<TaskFilterInput>;
  completedAt?: InputMaybe<FilterExpression>;
  completedById?: InputMaybe<FilterExpression>;
  dueDate?: InputMaybe<FilterExpression>;
  or?: InputMaybe<TaskFilterInput>;
  patientId?: InputMaybe<FilterExpression>;
  reason?: InputMaybe<FilterExpression>;
  status?: InputMaybe<FilterExpression>;
  type?: InputMaybe<FilterExpression>;
};

export type Template = {
  __typename?: 'Template';
  data: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type TemplateFilterInput = {
  and?: InputMaybe<TemplateFilterInput>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<TemplateFilterInput>;
  subject?: InputMaybe<FilterExpression>;
  tenantId?: InputMaybe<FilterExpression>;
  type?: InputMaybe<FilterExpression>;
};

export type Tenant = {
  __typename?: 'Tenant';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TenantAppDevice = {
  __typename?: 'TenantAppDevice';
  currentLoggedInUserId: Scalars['String']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  hardwareId: Scalars['String']['output'];
  hardwareName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type TenantAppDevicePatient = {
  __typename?: 'TenantAppDevicePatient';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  startAt: Scalars['DateTime']['output'];
  tenantAppDeviceId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TenantFilterInput = {
  and?: InputMaybe<TenantFilterInput>;
  name?: InputMaybe<FilterExpression>;
  or?: InputMaybe<TenantFilterInput>;
};

export type TimeTracking = {
  __typename?: 'TimeTracking';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  doctorId: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  patientId: Scalars['String']['output'];
};

export type UpdateCareGiverInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<RelationType>;
};

export type UpdateConditionInput = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  measurementIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  symptomIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateContentInput = {
  tags?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDeviceInput = {
  category?: InputMaybe<DeviceCategory>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  managedBy?: InputMaybe<ManagedBy>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  measurementIds?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DeviceType>;
};

export type UpdateDocumentationInput = {
  capturedAt?: InputMaybe<Scalars['DateTime']['input']>;
  capturedById?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  reviewedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reviewedById?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentationStatusType>;
};

export type UpdateInvitedUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PatientGender>;
  height?: InputMaybe<Scalars['Float']['input']>;
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMeasurementInput = {
  bucket?: InputMaybe<MeasurementBucketType>;
  dataPrecision?: InputMaybe<Scalars['Float']['input']>;
  defaultTarget?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayType?: InputMaybe<MeasurementDisplayType>;
  indicator?: InputMaybe<Scalars['String']['input']>;
  maxValue?: InputMaybe<Scalars['Float']['input']>;
  minValue?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MeasurementType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  message: Scalars['String']['input'];
};

export type UpdateNotificationInput = {
  errorText?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  readAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdatePatientConditionInput = {
  conditionId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePatientDeviceInput = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceInfo?: InputMaybe<Scalars['JSON']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePatientInput = {
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  gender?: InputMaybe<PatientGender>;
  height?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePatientMeasurementInput = {
  alertAbove?: InputMaybe<Scalars['Float']['input']>;
  alertBelow?: InputMaybe<Scalars['Float']['input']>;
  measurementId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePatientPrescribedDeviceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceStatus?: InputMaybe<PatientDeviceStatus>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DeviceStatus>;
};

export type UpdatePatientSymptomInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  occuredAt?: InputMaybe<Scalars['DateTime']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  symptomId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSharedDeviceInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  hardwareId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSymptomInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSystemNotificationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskInput = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  completedById?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusType>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isSignedUp: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  medicareId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserFilterInput = {
  address?: InputMaybe<FilterExpression>;
  and?: InputMaybe<UserFilterInput>;
  city?: InputMaybe<FilterExpression>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<FilterExpression>;
  externalId?: InputMaybe<FilterExpression>;
  firstName?: InputMaybe<FilterExpression>;
  isConfirmed?: InputMaybe<FilterExpression>;
  isEnabled?: InputMaybe<FilterExpression>;
  lastName?: InputMaybe<FilterExpression>;
  medicareId?: InputMaybe<FilterExpression>;
  or?: InputMaybe<UserFilterInput>;
  phoneNumber?: InputMaybe<FilterExpression>;
  role?: InputMaybe<FilterExpression>;
  state?: InputMaybe<FilterExpression>;
  zipCode?: InputMaybe<FilterExpression>;
};

export enum UserRole {
  Admin = 'Admin',
  CareGiver = 'CareGiver',
  Doctor = 'Doctor',
  FalconeerAdmin = 'FalconeerAdmin',
  MedicalStaff = 'MedicalStaff',
  Patient = 'Patient',
  PersonalAssistant = 'PersonalAssistant',
  RegisteredNurse = 'RegisteredNurse'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AssignTenantDeviceInput: AssignTenantDeviceInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CaregiverFilterInput: CaregiverFilterInput;
  Condition: ResolverTypeWrapper<Condition>;
  ConditionFilterInput: ConditionFilterInput;
  Content: ResolverTypeWrapper<Content>;
  ContentFilterInput: ContentFilterInput;
  ContentType: ContentType;
  CreateCaregiverInput: CreateCaregiverInput;
  CreateConditionInput: CreateConditionInput;
  CreateContentInput: CreateContentInput;
  CreateDeviceInput: CreateDeviceInput;
  CreateDocumentationInput: CreateDocumentationInput;
  CreateInvitedUserInput: CreateInvitedUserInput;
  CreateMeasurementInput: CreateMeasurementInput;
  CreateMessageInput: CreateMessageInput;
  CreateNotificationInput: CreateNotificationInput;
  CreatePatientConditionInput: CreatePatientConditionInput;
  CreatePatientDeviceInput: CreatePatientDeviceInput;
  CreatePatientInput: CreatePatientInput;
  CreatePatientMeasurementInput: CreatePatientMeasurementInput;
  CreatePatientPrescribedDeviceInput: CreatePatientPrescribedDeviceInput;
  CreatePatientSymptomInput: CreatePatientSymptomInput;
  CreateSharedDeviceInput: CreateSharedDeviceInput;
  CreateSymptomInput: CreateSymptomInput;
  CreateSystemNotificationInput: CreateSystemNotificationInput;
  CreateTaskInput: CreateTaskInput;
  CreateTimeTrackingInput: CreateTimeTrackingInput;
  CreateUserInput: CreateUserInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Device: ResolverTypeWrapper<Device>;
  DeviceCategory: DeviceCategory;
  DeviceFilterInput: DeviceFilterInput;
  DeviceStatus: DeviceStatus;
  DeviceType: DeviceType;
  Documentation: ResolverTypeWrapper<Documentation>;
  DocumentationFilterInput: DocumentationFilterInput;
  DocumentationStatusType: DocumentationStatusType;
  Duration: ResolverTypeWrapper<Duration>;
  ExternalPatientCondition: ResolverTypeWrapper<ExternalPatientCondition>;
  ExternalPatientConditionFilterInput: ExternalPatientConditionFilterInput;
  FilterExpression: FilterExpression;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InvitedUser: ResolverTypeWrapper<InvitedUser>;
  InvitedUserFilterInput: InvitedUserFilterInput;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  ListInvitedUser: ResolverTypeWrapper<ListInvitedUser>;
  ListMessages: ResolverTypeWrapper<ListMessages>;
  ListPatient: ResolverTypeWrapper<ListPatient>;
  ListSharedDevice: ResolverTypeWrapper<ListSharedDevice>;
  ListTasks: ResolverTypeWrapper<ListTasks>;
  ManagedBy: ManagedBy;
  Measurement: ResolverTypeWrapper<Measurement>;
  MeasurementBucketType: MeasurementBucketType;
  MeasurementDisplayType: MeasurementDisplayType;
  MeasurementFilterInput: MeasurementFilterInput;
  MeasurementType: MeasurementType;
  Message: ResolverTypeWrapper<Message>;
  MessageFilterInput: MessageFilterInput;
  MessageUser: ResolverTypeWrapper<MessageUser>;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationFilterInput: NotificationFilterInput;
  Orders: ResolverTypeWrapper<Orders>;
  PaginationInput: PaginationInput;
  Patient: ResolverTypeWrapper<Patient>;
  PatientCondition: ResolverTypeWrapper<PatientCondition>;
  PatientConditionFilterInput: PatientConditionFilterInput;
  PatientDevice: ResolverTypeWrapper<PatientDevice>;
  PatientDeviceFilterInput: PatientDeviceFilterInput;
  PatientDeviceStatus: PatientDeviceStatus;
  PatientFilterInput: PatientFilterInput;
  PatientGender: PatientGender;
  PatientMeasurement: ResolverTypeWrapper<PatientMeasurement>;
  PatientMeasurementFilterInput: PatientMeasurementFilterInput;
  PatientPrescribedDevice: ResolverTypeWrapper<PatientPrescribedDevice>;
  PatientPrescribedDeviceFilterInput: PatientPrescribedDeviceFilterInput;
  PatientSymptom: ResolverTypeWrapper<PatientSymptom>;
  PatientSymptomFilterInput: PatientSymptomFilterInput;
  Query: ResolverTypeWrapper<{}>;
  ROle: ResolverTypeWrapper<ROle>;
  RelationType: RelationType;
  RoleFilterInput: RoleFilterInput;
  SearchInput: SearchInput;
  SearchTaskInput: SearchTaskInput;
  SearchUserInput: SearchUserInput;
  SharedDevice: ResolverTypeWrapper<SharedDevice>;
  SharedDeviceFilterInput: SharedDeviceFilterInput;
  SortDirection: SortDirection;
  SortOrder: SortOrder;
  StatusType: StatusType;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SwitchPatientInput: SwitchPatientInput;
  Symptom: ResolverTypeWrapper<Symptom>;
  SymptomFilterInput: SymptomFilterInput;
  SystemNotification: ResolverTypeWrapper<SystemNotification>;
  SystemNotificationFilterInput: SystemNotificationFilterInput;
  Task: ResolverTypeWrapper<Task>;
  TaskFilterInput: TaskFilterInput;
  Template: ResolverTypeWrapper<Template>;
  TemplateFilterInput: TemplateFilterInput;
  Tenant: ResolverTypeWrapper<Tenant>;
  TenantAppDevice: ResolverTypeWrapper<TenantAppDevice>;
  TenantAppDevicePatient: ResolverTypeWrapper<TenantAppDevicePatient>;
  TenantFilterInput: TenantFilterInput;
  TimeTracking: ResolverTypeWrapper<TimeTracking>;
  UpdateCareGiverInput: UpdateCareGiverInput;
  UpdateConditionInput: UpdateConditionInput;
  UpdateContentInput: UpdateContentInput;
  UpdateDeviceInput: UpdateDeviceInput;
  UpdateDocumentationInput: UpdateDocumentationInput;
  UpdateInvitedUserInput: UpdateInvitedUserInput;
  UpdateMeasurementInput: UpdateMeasurementInput;
  UpdateMessageInput: UpdateMessageInput;
  UpdateNotificationInput: UpdateNotificationInput;
  UpdatePatientConditionInput: UpdatePatientConditionInput;
  UpdatePatientDeviceInput: UpdatePatientDeviceInput;
  UpdatePatientInput: UpdatePatientInput;
  UpdatePatientMeasurementInput: UpdatePatientMeasurementInput;
  UpdatePatientPrescribedDeviceInput: UpdatePatientPrescribedDeviceInput;
  UpdatePatientSymptomInput: UpdatePatientSymptomInput;
  UpdateSharedDeviceInput: UpdateSharedDeviceInput;
  UpdateSymptomInput: UpdateSymptomInput;
  UpdateSystemNotificationInput: UpdateSystemNotificationInput;
  UpdateTaskInput: UpdateTaskInput;
  UpdateUserInput: UpdateUserInput;
  User: ResolverTypeWrapper<User>;
  UserFilterInput: UserFilterInput;
  UserRole: UserRole;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AssignTenantDeviceInput: AssignTenantDeviceInput;
  Boolean: Scalars['Boolean']['output'];
  CaregiverFilterInput: CaregiverFilterInput;
  Condition: Condition;
  ConditionFilterInput: ConditionFilterInput;
  Content: Content;
  ContentFilterInput: ContentFilterInput;
  CreateCaregiverInput: CreateCaregiverInput;
  CreateConditionInput: CreateConditionInput;
  CreateContentInput: CreateContentInput;
  CreateDeviceInput: CreateDeviceInput;
  CreateDocumentationInput: CreateDocumentationInput;
  CreateInvitedUserInput: CreateInvitedUserInput;
  CreateMeasurementInput: CreateMeasurementInput;
  CreateMessageInput: CreateMessageInput;
  CreateNotificationInput: CreateNotificationInput;
  CreatePatientConditionInput: CreatePatientConditionInput;
  CreatePatientDeviceInput: CreatePatientDeviceInput;
  CreatePatientInput: CreatePatientInput;
  CreatePatientMeasurementInput: CreatePatientMeasurementInput;
  CreatePatientPrescribedDeviceInput: CreatePatientPrescribedDeviceInput;
  CreatePatientSymptomInput: CreatePatientSymptomInput;
  CreateSharedDeviceInput: CreateSharedDeviceInput;
  CreateSymptomInput: CreateSymptomInput;
  CreateSystemNotificationInput: CreateSystemNotificationInput;
  CreateTaskInput: CreateTaskInput;
  CreateTimeTrackingInput: CreateTimeTrackingInput;
  CreateUserInput: CreateUserInput;
  DateTime: Scalars['DateTime']['output'];
  Device: Device;
  DeviceFilterInput: DeviceFilterInput;
  Documentation: Documentation;
  DocumentationFilterInput: DocumentationFilterInput;
  Duration: Duration;
  ExternalPatientCondition: ExternalPatientCondition;
  ExternalPatientConditionFilterInput: ExternalPatientConditionFilterInput;
  FilterExpression: FilterExpression;
  Float: Scalars['Float']['output'];
  Int: Scalars['Int']['output'];
  InvitedUser: InvitedUser;
  InvitedUserFilterInput: InvitedUserFilterInput;
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  ListInvitedUser: ListInvitedUser;
  ListMessages: ListMessages;
  ListPatient: ListPatient;
  ListSharedDevice: ListSharedDevice;
  ListTasks: ListTasks;
  Measurement: Measurement;
  MeasurementFilterInput: MeasurementFilterInput;
  Message: Message;
  MessageFilterInput: MessageFilterInput;
  MessageUser: MessageUser;
  Mutation: {};
  Notification: Notification;
  NotificationFilterInput: NotificationFilterInput;
  Orders: Orders;
  PaginationInput: PaginationInput;
  Patient: Patient;
  PatientCondition: PatientCondition;
  PatientConditionFilterInput: PatientConditionFilterInput;
  PatientDevice: PatientDevice;
  PatientDeviceFilterInput: PatientDeviceFilterInput;
  PatientFilterInput: PatientFilterInput;
  PatientMeasurement: PatientMeasurement;
  PatientMeasurementFilterInput: PatientMeasurementFilterInput;
  PatientPrescribedDevice: PatientPrescribedDevice;
  PatientPrescribedDeviceFilterInput: PatientPrescribedDeviceFilterInput;
  PatientSymptom: PatientSymptom;
  PatientSymptomFilterInput: PatientSymptomFilterInput;
  Query: {};
  ROle: ROle;
  RoleFilterInput: RoleFilterInput;
  SearchInput: SearchInput;
  SearchTaskInput: SearchTaskInput;
  SearchUserInput: SearchUserInput;
  SharedDevice: SharedDevice;
  SharedDeviceFilterInput: SharedDeviceFilterInput;
  SortOrder: SortOrder;
  String: Scalars['String']['output'];
  SwitchPatientInput: SwitchPatientInput;
  Symptom: Symptom;
  SymptomFilterInput: SymptomFilterInput;
  SystemNotification: SystemNotification;
  SystemNotificationFilterInput: SystemNotificationFilterInput;
  Task: Task;
  TaskFilterInput: TaskFilterInput;
  Template: Template;
  TemplateFilterInput: TemplateFilterInput;
  Tenant: Tenant;
  TenantAppDevice: TenantAppDevice;
  TenantAppDevicePatient: TenantAppDevicePatient;
  TenantFilterInput: TenantFilterInput;
  TimeTracking: TimeTracking;
  UpdateCareGiverInput: UpdateCareGiverInput;
  UpdateConditionInput: UpdateConditionInput;
  UpdateContentInput: UpdateContentInput;
  UpdateDeviceInput: UpdateDeviceInput;
  UpdateDocumentationInput: UpdateDocumentationInput;
  UpdateInvitedUserInput: UpdateInvitedUserInput;
  UpdateMeasurementInput: UpdateMeasurementInput;
  UpdateMessageInput: UpdateMessageInput;
  UpdateNotificationInput: UpdateNotificationInput;
  UpdatePatientConditionInput: UpdatePatientConditionInput;
  UpdatePatientDeviceInput: UpdatePatientDeviceInput;
  UpdatePatientInput: UpdatePatientInput;
  UpdatePatientMeasurementInput: UpdatePatientMeasurementInput;
  UpdatePatientPrescribedDeviceInput: UpdatePatientPrescribedDeviceInput;
  UpdatePatientSymptomInput: UpdatePatientSymptomInput;
  UpdateSharedDeviceInput: UpdateSharedDeviceInput;
  UpdateSymptomInput: UpdateSymptomInput;
  UpdateSystemNotificationInput: UpdateSystemNotificationInput;
  UpdateTaskInput: UpdateTaskInput;
  UpdateUserInput: UpdateUserInput;
  User: User;
  UserFilterInput: UserFilterInput;
};

export type ConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition']> = {
  acronym?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  measurements?: Resolver<Maybe<Array<ResolversTypes['Measurement']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symptoms?: Resolver<Maybe<Array<ResolversTypes['Symptom']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = {
  category?: Resolver<ResolversTypes['DeviceCategory'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managedBy?: Resolver<ResolversTypes['ManagedBy'], ParentType, ContextType>;
  manufacturer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  measurements?: Resolver<Array<ResolversTypes['Measurement']>, ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Documentation'] = ResolversParentTypes['Documentation']> = {
  capturedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  capturedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  capturedById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reviewedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reviewedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  reviewedById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DocumentationStatusType'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Duration'] = ResolversParentTypes['Duration']> = {
  totalDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalPatientConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalPatientCondition'] = ResolversParentTypes['ExternalPatientCondition']> = {
  classificationDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clinicalStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditionCode?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  conditionId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  icd10?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icd10Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onsetDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  principalDiagnosis?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rankDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revisionBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revisionDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  therapy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitedUser'] = ResolversParentTypes['InvitedUser']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['PatientGender'], ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invitedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relation?: Resolver<Maybe<ResolversTypes['RelationType']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<ResolversTypes['ROle'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<ResolversTypes['Tenant'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type ListInvitedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListInvitedUser'] = ResolversParentTypes['ListInvitedUser']> = {
  items?: Resolver<Array<ResolversTypes['InvitedUser']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListMessages'] = ResolversParentTypes['ListMessages']> = {
  items?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListPatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListPatient'] = ResolversParentTypes['ListPatient']> = {
  items?: Resolver<Array<ResolversTypes['Patient']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListSharedDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListSharedDevice'] = ResolversParentTypes['ListSharedDevice']> = {
  items?: Resolver<Array<ResolversTypes['SharedDevice']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListTasksResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListTasks'] = ResolversParentTypes['ListTasks']> = {
  items?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeasurementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Measurement'] = ResolversParentTypes['Measurement']> = {
  bucket?: Resolver<ResolversTypes['MeasurementBucketType'], ParentType, ContextType>;
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dataPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  defaultTarget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  devices?: Resolver<Maybe<Array<ResolversTypes['Device']>>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayType?: Resolver<ResolversTypes['MeasurementDisplayType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  indicator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MeasurementType'], ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['MessageUser']>, ParentType, ContextType>;
  fromId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['MessageUser']>, ParentType, ContextType>;
  toId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageUser'] = ResolversParentTypes['MessageUser']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  assignTenantAppDevice?: Resolver<ResolversTypes['SharedDevice'], ParentType, ContextType, RequireFields<MutationAssignTenantAppDeviceArgs, 'input'>>;
  createCaregiver?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationCreateCaregiverArgs, 'input'>>;
  createCondition?: Resolver<ResolversTypes['Condition'], ParentType, ContextType, RequireFields<MutationCreateConditionArgs, 'input'>>;
  createContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationCreateContentArgs, 'input'>>;
  createDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<MutationCreateDeviceArgs, 'input'>>;
  createDocumentation?: Resolver<ResolversTypes['Documentation'], ParentType, ContextType, RequireFields<MutationCreateDocumentationArgs, 'input'>>;
  createInvitedUsers?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationCreateInvitedUsersArgs, 'input'>>;
  createMeasurement?: Resolver<ResolversTypes['Measurement'], ParentType, ContextType, RequireFields<MutationCreateMeasurementArgs, 'input'>>;
  createMessage?: Resolver<ResolversTypes['Message'], ParentType, ContextType, RequireFields<MutationCreateMessageArgs, 'input'>>;
  createNotification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationCreateNotificationArgs, 'input'>>;
  createPatient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType, RequireFields<MutationCreatePatientArgs, 'input'>>;
  createPatientCondition?: Resolver<ResolversTypes['PatientCondition'], ParentType, ContextType, RequireFields<MutationCreatePatientConditionArgs, 'input'>>;
  createPatientDevice?: Resolver<ResolversTypes['PatientDevice'], ParentType, ContextType, RequireFields<MutationCreatePatientDeviceArgs, 'input'>>;
  createPatientMeasurement?: Resolver<ResolversTypes['PatientMeasurement'], ParentType, ContextType, RequireFields<MutationCreatePatientMeasurementArgs, 'input'>>;
  createPatientPrescribedDevice?: Resolver<ResolversTypes['PatientPrescribedDevice'], ParentType, ContextType, RequireFields<MutationCreatePatientPrescribedDeviceArgs, 'input'>>;
  createPatientSymptom?: Resolver<ResolversTypes['PatientSymptom'], ParentType, ContextType, RequireFields<MutationCreatePatientSymptomArgs, 'input'>>;
  createSharedDevice?: Resolver<ResolversTypes['SharedDevice'], ParentType, ContextType, RequireFields<MutationCreateSharedDeviceArgs, 'input'>>;
  createSymptom?: Resolver<ResolversTypes['Symptom'], ParentType, ContextType, RequireFields<MutationCreateSymptomArgs, 'input'>>;
  createSystemNotification?: Resolver<ResolversTypes['SystemNotification'], ParentType, ContextType, RequireFields<MutationCreateSystemNotificationArgs, 'input'>>;
  createTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationCreateTaskArgs, 'input'>>;
  createTimeTracking?: Resolver<ResolversTypes['TimeTracking'], ParentType, ContextType, RequireFields<MutationCreateTimeTrackingArgs, 'input'>>;
  createUsers?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUsersArgs, 'input'>>;
  deleteCaregiver?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationDeleteCaregiverArgs, 'id'>>;
  deleteCondition?: Resolver<ResolversTypes['Condition'], ParentType, ContextType, RequireFields<MutationDeleteConditionArgs, 'id'>>;
  deleteContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationDeleteContentArgs, 'id'>>;
  deleteDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<MutationDeleteDeviceArgs, 'id'>>;
  deleteDocumentation?: Resolver<ResolversTypes['Documentation'], ParentType, ContextType, RequireFields<MutationDeleteDocumentationArgs, 'id'>>;
  deleteInvitedUsers?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationDeleteInvitedUsersArgs, 'id'>>;
  deleteMeasurements?: Resolver<ResolversTypes['Measurement'], ParentType, ContextType, RequireFields<MutationDeleteMeasurementsArgs, 'id'>>;
  deleteMessage?: Resolver<ResolversTypes['Message'], ParentType, ContextType, RequireFields<MutationDeleteMessageArgs, 'id'>>;
  deleteNotification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationDeleteNotificationArgs, 'id'>>;
  deletePatient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType, RequireFields<MutationDeletePatientArgs, 'id'>>;
  deletePatientCondition?: Resolver<ResolversTypes['PatientCondition'], ParentType, ContextType, RequireFields<MutationDeletePatientConditionArgs, 'id'>>;
  deletePatientDevices?: Resolver<ResolversTypes['PatientDevice'], ParentType, ContextType, RequireFields<MutationDeletePatientDevicesArgs, 'id'>>;
  deletePatientMeasurements?: Resolver<ResolversTypes['PatientMeasurement'], ParentType, ContextType, RequireFields<MutationDeletePatientMeasurementsArgs, 'id'>>;
  deletePatientPrescribedDevices?: Resolver<ResolversTypes['PatientPrescribedDevice'], ParentType, ContextType, RequireFields<MutationDeletePatientPrescribedDevicesArgs, 'id'>>;
  deletePatientSymptoms?: Resolver<ResolversTypes['PatientSymptom'], ParentType, ContextType, RequireFields<MutationDeletePatientSymptomsArgs, 'id'>>;
  deleteSharedDevice?: Resolver<ResolversTypes['SharedDevice'], ParentType, ContextType, RequireFields<MutationDeleteSharedDeviceArgs, 'id'>>;
  deleteSymptom?: Resolver<ResolversTypes['Symptom'], ParentType, ContextType, RequireFields<MutationDeleteSymptomArgs, 'id'>>;
  deleteSystemNotification?: Resolver<ResolversTypes['SystemNotification'], ParentType, ContextType, RequireFields<MutationDeleteSystemNotificationArgs, 'id'>>;
  deleteTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationDeleteTaskArgs, 'id'>>;
  deleteUsers?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationDeleteUsersArgs, 'id'>>;
  switchPatient?: Resolver<ResolversTypes['TenantAppDevicePatient'], ParentType, ContextType, RequireFields<MutationSwitchPatientArgs, 'input'>>;
  updateCaregiver?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationUpdateCaregiverArgs, 'id' | 'input'>>;
  updateCondition?: Resolver<ResolversTypes['Condition'], ParentType, ContextType, RequireFields<MutationUpdateConditionArgs, 'id' | 'input'>>;
  updateContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationUpdateContentArgs, 'id' | 'input'>>;
  updateDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<MutationUpdateDeviceArgs, 'id' | 'input'>>;
  updateDeviceStatus?: Resolver<ResolversTypes['PatientPrescribedDevice'], ParentType, ContextType, RequireFields<MutationUpdateDeviceStatusArgs, 'deviceId' | 'input'>>;
  updateDocumentation?: Resolver<ResolversTypes['Documentation'], ParentType, ContextType, RequireFields<MutationUpdateDocumentationArgs, 'id' | 'input'>>;
  updateInvitedUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationUpdateInvitedUserArgs, 'id' | 'input'>>;
  updateMeasurement?: Resolver<ResolversTypes['Measurement'], ParentType, ContextType, RequireFields<MutationUpdateMeasurementArgs, 'id' | 'input'>>;
  updateMessage?: Resolver<ResolversTypes['Message'], ParentType, ContextType, RequireFields<MutationUpdateMessageArgs, 'id' | 'input'>>;
  updateNotification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationUpdateNotificationArgs, 'id' | 'input'>>;
  updatePatient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType, RequireFields<MutationUpdatePatientArgs, 'id' | 'input'>>;
  updatePatientCondition?: Resolver<ResolversTypes['PatientCondition'], ParentType, ContextType, RequireFields<MutationUpdatePatientConditionArgs, 'id' | 'input'>>;
  updatePatientDevice?: Resolver<ResolversTypes['PatientDevice'], ParentType, ContextType, RequireFields<MutationUpdatePatientDeviceArgs, 'id' | 'input'>>;
  updatePatientMeasurement?: Resolver<ResolversTypes['PatientMeasurement'], ParentType, ContextType, RequireFields<MutationUpdatePatientMeasurementArgs, 'id' | 'input'>>;
  updatePatientPrescribedDevice?: Resolver<ResolversTypes['PatientPrescribedDevice'], ParentType, ContextType, RequireFields<MutationUpdatePatientPrescribedDeviceArgs, 'id' | 'input'>>;
  updatePatientSymptom?: Resolver<ResolversTypes['PatientSymptom'], ParentType, ContextType, RequireFields<MutationUpdatePatientSymptomArgs, 'id' | 'input'>>;
  updateSharedDevice?: Resolver<ResolversTypes['SharedDevice'], ParentType, ContextType, RequireFields<MutationUpdateSharedDeviceArgs, 'id' | 'input'>>;
  updateSymptom?: Resolver<ResolversTypes['Symptom'], ParentType, ContextType, RequireFields<MutationUpdateSymptomArgs, 'id' | 'input'>>;
  updateSystemNotification?: Resolver<ResolversTypes['SystemNotification'], ParentType, ContextType, RequireFields<MutationUpdateSystemNotificationArgs, 'id' | 'input'>>;
  updateTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationUpdateTaskArgs, 'id' | 'input'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'input'>>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  readAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  retryAttempt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sendStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Orders'] = ResolversParentTypes['Orders']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Patient'] = ResolversParentTypes['Patient']> = {
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['PatientGender'], ParentType, ContextType>;
  healthDataUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  height?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientCondition'] = ResolversParentTypes['PatientCondition']> = {
  condition?: Resolver<ResolversTypes['Condition'], ParentType, ContextType>;
  conditionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientDevice'] = ResolversParentTypes['PatientDevice']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceInfo?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientMeasurementResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientMeasurement'] = ResolversParentTypes['PatientMeasurement']> = {
  alertAbove?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alertBelow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  measurement?: Resolver<ResolversTypes['Measurement'], ParentType, ContextType>;
  measurementId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientPrescribedDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientPrescribedDevice'] = ResolversParentTypes['PatientPrescribedDevice']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceStatus?: Resolver<ResolversTypes['PatientDeviceStatus'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orders?: Resolver<Array<ResolversTypes['Orders']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientDevice?: Resolver<Maybe<ResolversTypes['PatientDevice']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DeviceStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientSymptomResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientSymptom'] = ResolversParentTypes['PatientSymptom']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  occuredAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  severityMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  symptom?: Resolver<ResolversTypes['Symptom'], ParentType, ContextType>;
  symptomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getCaregiver?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<QueryGetCaregiverArgs, 'id'>>;
  getCondition?: Resolver<ResolversTypes['Condition'], ParentType, ContextType, RequireFields<QueryGetConditionArgs, 'id'>>;
  getContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<QueryGetContentArgs, 'id'>>;
  getDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<QueryGetDeviceArgs, 'id'>>;
  getDocumentation?: Resolver<ResolversTypes['Documentation'], ParentType, ContextType, RequireFields<QueryGetDocumentationArgs, 'id'>>;
  getInvitedUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<QueryGetInvitedUserArgs, 'id'>>;
  getMeasurement?: Resolver<ResolversTypes['Measurement'], ParentType, ContextType, RequireFields<QueryGetMeasurementArgs, 'id'>>;
  getMessage?: Resolver<ResolversTypes['Message'], ParentType, ContextType, RequireFields<QueryGetMessageArgs, 'id'>>;
  getPatient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType, RequireFields<QueryGetPatientArgs, 'id'>>;
  getPatientCondition?: Resolver<ResolversTypes['PatientCondition'], ParentType, ContextType, RequireFields<QueryGetPatientConditionArgs, 'id'>>;
  getPatientDevice?: Resolver<ResolversTypes['PatientDevice'], ParentType, ContextType, RequireFields<QueryGetPatientDeviceArgs, 'id'>>;
  getPatientMeasurement?: Resolver<ResolversTypes['PatientMeasurement'], ParentType, ContextType, RequireFields<QueryGetPatientMeasurementArgs, 'id'>>;
  getPatientPrescribedDevice?: Resolver<ResolversTypes['PatientPrescribedDevice'], ParentType, ContextType, RequireFields<QueryGetPatientPrescribedDeviceArgs, 'id'>>;
  getPatientSymptom?: Resolver<ResolversTypes['PatientSymptom'], ParentType, ContextType, RequireFields<QueryGetPatientSymptomArgs, 'id'>>;
  getSharedDevice?: Resolver<ResolversTypes['SharedDevice'], ParentType, ContextType, RequireFields<QueryGetSharedDeviceArgs, 'id'>>;
  getSymptom?: Resolver<ResolversTypes['Symptom'], ParentType, ContextType, RequireFields<QueryGetSymptomArgs, 'id'>>;
  getSystemNotification?: Resolver<ResolversTypes['SystemNotification'], ParentType, ContextType, RequireFields<QueryGetSystemNotificationArgs, 'id'>>;
  getTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<QueryGetTaskArgs, 'id'>>;
  getTimeTrackingSummary?: Resolver<ResolversTypes['Duration'], ParentType, ContextType, RequireFields<QueryGetTimeTrackingSummaryArgs, 'doctorId' | 'patientId'>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetUserArgs, 'id'>>;
  listCaregivers?: Resolver<Array<ResolversTypes['InvitedUser']>, ParentType, ContextType, Partial<QueryListCaregiversArgs>>;
  listConditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType, Partial<QueryListConditionsArgs>>;
  listContents?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType, Partial<QueryListContentsArgs>>;
  listDevices?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType, Partial<QueryListDevicesArgs>>;
  listDocumentation?: Resolver<Array<ResolversTypes['Documentation']>, ParentType, ContextType, Partial<QueryListDocumentationArgs>>;
  listExternalPatientCondition?: Resolver<Array<ResolversTypes['ExternalPatientCondition']>, ParentType, ContextType, Partial<QueryListExternalPatientConditionArgs>>;
  listInvitedUsers?: Resolver<ResolversTypes['ListInvitedUser'], ParentType, ContextType, Partial<QueryListInvitedUsersArgs>>;
  listMeasurements?: Resolver<Array<ResolversTypes['Measurement']>, ParentType, ContextType, Partial<QueryListMeasurementsArgs>>;
  listMessages?: Resolver<ResolversTypes['ListMessages'], ParentType, ContextType, Partial<QueryListMessagesArgs>>;
  listNotifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType, Partial<QueryListNotificationsArgs>>;
  listPatientCondition?: Resolver<Array<ResolversTypes['PatientCondition']>, ParentType, ContextType, Partial<QueryListPatientConditionArgs>>;
  listPatientDevices?: Resolver<Array<ResolversTypes['PatientDevice']>, ParentType, ContextType, Partial<QueryListPatientDevicesArgs>>;
  listPatientMeasurements?: Resolver<Array<ResolversTypes['PatientMeasurement']>, ParentType, ContextType, Partial<QueryListPatientMeasurementsArgs>>;
  listPatientPrescribedDevices?: Resolver<Array<ResolversTypes['PatientPrescribedDevice']>, ParentType, ContextType, Partial<QueryListPatientPrescribedDevicesArgs>>;
  listPatientSymptoms?: Resolver<Array<ResolversTypes['PatientSymptom']>, ParentType, ContextType, Partial<QueryListPatientSymptomsArgs>>;
  listPatients?: Resolver<ResolversTypes['ListPatient'], ParentType, ContextType, Partial<QueryListPatientsArgs>>;
  listRole?: Resolver<Array<ResolversTypes['ROle']>, ParentType, ContextType, Partial<QueryListRoleArgs>>;
  listSharedDevice?: Resolver<ResolversTypes['ListSharedDevice'], ParentType, ContextType, Partial<QueryListSharedDeviceArgs>>;
  listSymptoms?: Resolver<Array<ResolversTypes['Symptom']>, ParentType, ContextType, Partial<QueryListSymptomsArgs>>;
  listSystemNotifications?: Resolver<Array<ResolversTypes['SystemNotification']>, ParentType, ContextType, Partial<QueryListSystemNotificationsArgs>>;
  listTasks?: Resolver<ResolversTypes['ListTasks'], ParentType, ContextType, Partial<QueryListTasksArgs>>;
  listTemplates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType, Partial<QueryListTemplatesArgs>>;
  listTenants?: Resolver<Array<ResolversTypes['Tenant']>, ParentType, ContextType, Partial<QueryListTenantsArgs>>;
  listUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryListUsersArgs>>;
};

export type ROleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ROle'] = ResolversParentTypes['ROle']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharedDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SharedDevice'] = ResolversParentTypes['SharedDevice']> = {
  appDevice?: Resolver<Maybe<ResolversTypes['TenantAppDevice']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currentAppDeviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hardwareId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SymptomResolvers<ContextType = any, ParentType extends ResolversParentTypes['Symptom'] = ResolversParentTypes['Symptom']> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemNotification'] = ResolversParentTypes['SystemNotification']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completedById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StatusType'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tenant'] = ResolversParentTypes['Tenant']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantAppDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantAppDevice'] = ResolversParentTypes['TenantAppDevice']> = {
  currentLoggedInUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  hardwareId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hardwareName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantAppDevicePatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantAppDevicePatient'] = ResolversParentTypes['TenantAppDevicePatient']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  tenantAppDeviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeTrackingResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeTracking'] = ResolversParentTypes['TimeTracking']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  doctorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSignedUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medicareId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Condition?: ConditionResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Device?: DeviceResolvers<ContextType>;
  Documentation?: DocumentationResolvers<ContextType>;
  Duration?: DurationResolvers<ContextType>;
  ExternalPatientCondition?: ExternalPatientConditionResolvers<ContextType>;
  InvitedUser?: InvitedUserResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  ListInvitedUser?: ListInvitedUserResolvers<ContextType>;
  ListMessages?: ListMessagesResolvers<ContextType>;
  ListPatient?: ListPatientResolvers<ContextType>;
  ListSharedDevice?: ListSharedDeviceResolvers<ContextType>;
  ListTasks?: ListTasksResolvers<ContextType>;
  Measurement?: MeasurementResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessageUser?: MessageUserResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  Orders?: OrdersResolvers<ContextType>;
  Patient?: PatientResolvers<ContextType>;
  PatientCondition?: PatientConditionResolvers<ContextType>;
  PatientDevice?: PatientDeviceResolvers<ContextType>;
  PatientMeasurement?: PatientMeasurementResolvers<ContextType>;
  PatientPrescribedDevice?: PatientPrescribedDeviceResolvers<ContextType>;
  PatientSymptom?: PatientSymptomResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ROle?: ROleResolvers<ContextType>;
  SharedDevice?: SharedDeviceResolvers<ContextType>;
  Symptom?: SymptomResolvers<ContextType>;
  SystemNotification?: SystemNotificationResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantAppDevice?: TenantAppDeviceResolvers<ContextType>;
  TenantAppDevicePatient?: TenantAppDevicePatientResolvers<ContextType>;
  TimeTracking?: TimeTrackingResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
};

