import React, { useEffect } from 'react';
import { EntityManager } from '../../components/entity-manager/EntityManager';
import { sharedDeviceConfig } from './SharedDeviceConfig';
import deviceService from '../../services/DeviceService';
import { QueryVariables } from '../../components/entity-manager/types';
import { Device, ManagedBy, Tenant } from '../../api/CoreAPI';
import FalconError, { E_FETCH_DEVICE, E_GET_TENANT_LIST } from '../../falconeer/FalconErrors';
import OverlayLoader from '../../components/OverlayLoader';
import tenantService from '../../services/TenantService';

type DropdownSchema = {
  const: string;
  title: string;
};

export const SharedDeviceListPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const setDeviceSchema = async () => {
    let options: DropdownSchema[] = [];

    try {
      setIsLoading(true);
      const filterData: QueryVariables = {
        filter: { managedBy: { eq: ManagedBy.System } },
      };
      const devices = await deviceService.getAll(filterData);
      devices.forEach((item: Device) => {
        const dropdownObj: DropdownSchema = {
          const: item.id,
          title: item.displayName,
        };
        options.push(dropdownObj);
      });
      sharedDeviceConfig.formSchema.properties.deviceId.oneOf = options;
    } catch {
      throw new FalconError(E_FETCH_DEVICE);
    } finally {
      setIsLoading(false);
    }
  };

  const setTenantSchema = async () => {
    let options: DropdownSchema[] = [];
    try {
      setIsLoading(true);
      const tenants = await tenantService.getAll();
      tenants.forEach((item: Tenant) => {
        const dropdownObj: DropdownSchema = {
          const: item.id,
          title: item.displayName,
        };
        options.push(dropdownObj);
      });
      sharedDeviceConfig.formSchema.properties.tenantId.oneOf = options;
    } catch (err: any) {
      throw new FalconError(E_GET_TENANT_LIST);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDeviceSchema();
    setTenantSchema();
  }, []);

  return (
    <>
      {<OverlayLoader isLoading={isLoading}></OverlayLoader>}
      {!isLoading && <EntityManager config={sharedDeviceConfig} />}
    </>
  );
};
