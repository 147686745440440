import { useMemo, useState, useEffect, createContext, useLayoutEffect } from 'react';
import { getAppTheme } from './styles/theme';
import { DARK_MODE_THEME, LIGHT_MODE_THEME, PAGE_PATH_ONBOARD_USER, PAGE_PATH_SHARED_DEVICE } from './utils/constants';
import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import { ThemeModeContext } from './falconeer/ThemeModeContext';
import { AppFooter } from './components/app-footer/AppFooter';
import AuthUser from './falconeer/AuthUser';
import { useNavigate } from 'react-router-dom';
import { useStore } from './falconeer/FalconStore';
import { LandingPage } from './LandingPage';
import './styles/CommonColors.css';
import { AppLayout } from './components/app-layout/AppLayout';
import '../src/pages/AuthScreens/AuthScreens.css';
import preLoginConfiguration from './PreLoginConfiguration';
import postLoginConfiguration from './PostLoginConfiguration';
import FalconError, { E_CONFIG_ERROR, E_INVALID_TOKEN, E_UNKNOWN, errorMessages } from './falconeer/FalconErrors';
import OverlayLoader from './components/OverlayLoader';
import routes from './routes';
import { Route, Routes } from 'react-router-dom';
import { AppRoute } from './types/Route';
import { PAGE_PATH_PRESCRIBED_DEVICES } from './utils/constants';
import { useMessageModalContext } from './components/dialog/MessageContextProvider';
import { UserRole } from './api/CoreAPI';

const getAuthUser = (data: any) => {
  return new AuthUser(
    data.id,
    data.email,
    data.email,
    data.phoneNumber,
    true,
    true,
    [data.roleName],
    data.firstName,
    data.lastName,
    data.tenant,
  );
};

export const SingInContext = createContext<any>(null);
const UNSECURED_ROUTES = routes.filter(route => route.type === 'unsecured');
const SECURED_ROUTES = routes.filter(route => route.type === 'secured');
const showAdminMenu = [UserRole.Admin, UserRole.FalconeerAdmin];
export const UnsavedContext = createContext<any>(null);

// JSW END
function App() {
  const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostConfigLoading, setIsPostLoginConfigLoading] = useState(true);
  const [isPreConfigLoading, setIsPreLoginConfigLoading] = useState(true);
  const [isPostLoginMethodCalled, setIsPostLoginMethodCalled] = useState(false);

  const falconStore = useStore();
  const [preLoginConfig, setPreLoginConfig] = useState<any>();
  const [userId, setUserId] = useState<string | null>(null);
  const [isUserSignedIn, setIsUserSignedIn] = useState<boolean>(false);
  const [unsavedPageData, setUnsavedPageData] = useState<boolean>(false);
  const navigate = useNavigate();
  const dialogContext = useMessageModalContext();

  const loadPostLoginConfig = async () => {
    if (isPostLoginMethodCalled) {
      return;
    }
    setIsPostLoginMethodCalled(true);
    let token: string;

    try {
      token = (await falconStore.getToken(false)) as string;
      await postLoginConfiguration.load(token);
    } catch (error: any) {
      if (error.errorCode != E_INVALID_TOKEN) {
        throw new FalconError(E_CONFIG_ERROR);
      }
      try {
        token = (await falconStore?.getToken(true)) as string;
        await postLoginConfiguration.load(token);
      } catch (error: any) {
        if (error.errorCode != E_INVALID_TOKEN) {
          throw new FalconError(E_CONFIG_ERROR);
        }
      }
    } finally {
      setIsPostLoginConfigLoading(false);
      setIsPostLoginMethodCalled(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const currentUser = window.localStorage.getItem('user')
          ? JSON.parse(window.localStorage.getItem('user')!)
          : null;
        const tenant = window.localStorage.getItem('tenant');
        const roleName = window.localStorage.getItem('roleName');
        if (currentUser !== null) {
          const authUser: AuthUser = getAuthUser({ ...currentUser, tenant, roleName });
          setUserId(currentUser.id);
          await loadPostLoginConfig();
          await falconStore.init(authUser);
        } else {
          setIsPostLoginConfigLoading(false);
        }
      } catch (e) {
        window.localStorage.clear();
        window.location.replace('/');

        console.error('User is not logged in');
      }
      setIsLoading(false);
    })();
    // if loadPostLoginConfig dependancy added it triggers multiple renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSignedIn]);

  useEffect(() => {
    if (userId === null) {
      window.localStorage.removeItem('userId');
    } else {
      window.localStorage.setItem('userId', userId);
    }
  }, [userId]);
  useLayoutEffect(() => {
    if (preLoginConfig === undefined) {
      setIsLoading(true);

      preLoginConfiguration
        .load()
        .then(config => {
          setPreLoginConfig(config);
        })
        .catch(err => {
          throw new FalconError(E_UNKNOWN);
        })
        .finally(() => {
          setIsPreLoginConfigLoading(false);
          setIsLoading(false);
        });
    }
  }, [preLoginConfig]);
  useEffect(() => {
    window.onunhandledrejection = e => {
      if (e.reason && e.reason.message && e.reason.message === 'No current user') {
        window.location.href = '/';
      } else if (window.location.pathname.includes('error') === false) {
        if (userId) {
          const errorMessage = !e.reason.message.includes(errorMessages[E_UNKNOWN])
            ? `${e.reason?.showExtraMessage ? '' : `${errorMessages[E_UNKNOWN]},`} ${e.reason.message} ${
                e.reason.showExtraMessage ? '' : ', Please try again.'
              }`
            : `${e.reason.message}, Please try again.`;
          dialogContext.error(errorMessage);
          return;
        }
        navigate('/app-error', { state: { errorMessage: e.reason.message } });
      }
    };
  }, [dialogContext, navigate, userId]);

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode(prevMode => (prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
      },
    }),
    [],
  );

  const theme = useMemo(() => getAppTheme(mode), [mode]);
  const currentUser = falconStore.getUser();
  const publicRoutes = UNSECURED_ROUTES.map((route: AppRoute) => (
    <Route key={`routePath-${route.path}`} path={route.path} element={route.component} />
  ));
  const protectedRoutes = SECURED_ROUTES.map((route: AppRoute) => {
    let element;
    if (route.component) {
      element = route.component;
    }
    if (route.path === PAGE_PATH_PRESCRIBED_DEVICES || route.path === PAGE_PATH_SHARED_DEVICE) {
      let show = currentUser
        ? currentUser.groups.some((group: string) => {
            return UserRole.Admin === group;
          })
        : false;
      if (show) {
        route.isMenu = true;
      } else {
        route.isMenu = false;
      }
    } else if (route.path === PAGE_PATH_ONBOARD_USER) {
      let show = currentUser
        ? currentUser.groups.some((group: string) => {
            return showAdminMenu.includes(group as UserRole);
          })
        : false;

      if (show) {
        route.isMenu = true;
      } else {
        route.isMenu = false;
      }
    }
    return <Route key={route.key} path={route.path} element={element} />;
  });

  return (
    <main>
      <ThemeModeContext.Provider value={themeMode}>
        <ThemeProvider theme={theme}>
          <SingInContext.Provider value={{ isUserSignedIn, setIsUserSignedIn }}>
            <UnsavedContext.Provider value={{ unsavedPageData, setUnsavedPageData }}>
              {isLoading || isPreConfigLoading || isPostConfigLoading ? (
                <>{<OverlayLoader isLoading={true}></OverlayLoader>}</>
              ) : (
                <>
                  <CssBaseline />
                  <Routes>
                    {<Route element={<AppLayout />}>{protectedRoutes}</Route>}
                    {<Route element={<LandingPage />}>{publicRoutes}</Route>}
                  </Routes>
                  <Box component='footer'>
                    <AppFooter />
                  </Box>
                </>
              )}
            </UnsavedContext.Provider>
          </SingInContext.Provider>
        </ThemeProvider>
      </ThemeModeContext.Provider>
    </main>
  );
}

export default App;
