import { FooterText, FooterWrapper } from '../../styles/CommonStyles';
import { COPYRIGHT_TEXT } from '../../utils/constants';

export const AppFooter = () => (
  <FooterWrapper>
    <FooterText variant='caption' color='textSecondary'>
      {COPYRIGHT_TEXT.replace('{{YEAR}}', new Date().getFullYear().toString())}
    </FooterText>
  </FooterWrapper>
);
