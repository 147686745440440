import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutline, RefreshOutlined } from '@mui/icons-material';
import { ActionHandler, CustomAction } from './types';
import { FPrimaryButton } from '../fcomponents';
import { Search } from './Search';

interface EntityTableToolbarProps {
  numSelected: number;
  addTooltip: string;
  deleteTooltip: string;
  actionHandler: ActionHandler;
  disableAdd: boolean;
  disableDelete: boolean;
  disableMultiSelect: boolean;
  customActions?: CustomAction[] | null;
  title: string;
  entitySearch: Function | undefined;
  setResponse: Function;
  showSearch: boolean | undefined;
  searchedText: string;
  setSearchText: Function;
  recordsPerPage: number;
  setCurrentRecordsDisplay: Function;
  setIsLastPage: Function;
  setCurrentPage: Function;
  currentPage: number;
}

export const EntityTableToolbar = (props: EntityTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}>
      <Typography sx={{ flex: '1 1 100%', fontSize: '20px' }} color='inherit' variant='subtitle1' component='div'>
        {props.title}
      </Typography>
      {props.showSearch && (
        <Search
          search={props.entitySearch}
          setResponseData={props.setResponse}
          searchedText={props.searchedText}
          setSearchData={props.setSearchText}
          recordsPerPage={props.recordsPerPage}
          setCurrentRecordsDisplay={props.setCurrentRecordsDisplay}
          setCurrentPage={props.setCurrentPage}
          setIsLastPage={props.setIsLastPage}
          currentPage={props.currentPage}
        />
      )}
      {props.customActions &&
        props.customActions.map((action, index) => (
          <FPrimaryButton key={index} variant='outlined' onClick={() => action.actionHandler()}>
            {action.title}
          </FPrimaryButton>
        ))}
      <Tooltip title={'Refresh'}>
        <span>
          <IconButton onClick={() => props.actionHandler('refresh', null)}>
            <RefreshOutlined />
          </IconButton>
        </span>
      </Tooltip>
      {!props.disableAdd && (
        <Tooltip title={props.addTooltip}>
          <span>
            <IconButton disabled={numSelected != 0} color='primary' onClick={() => props.actionHandler('add', null)}>
              <AddCircleOutline />
            </IconButton>
            {/* <Link to='/forms-add'>
              <IconButton disabled={numSelected != 0} color='primary'>
                <AddCircleOutline />
              </IconButton>
            </Link> */}
          </span>
        </Tooltip>
      )}
      {!props.disableDelete && !props.disableMultiSelect && (
        <Tooltip title={props.deleteTooltip}>
          <span>
            {/* span needed here otherwise MUI complains when disabled button is given under tooltip */}
            <IconButton disabled={numSelected === 0} onClick={() => props.actionHandler('delete', null)}>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Toolbar>
  );
};
