import { CreateSharedDeviceInput, SharedDevice, UpdateSharedDeviceInput } from '../../api/CoreAPI';
import {
  TableColumn as EntityDisplayAttribute,
  EntityConfig,
  DataFetcher,
  QueryVariables,
  ResponseData,
  ActionHandler,
} from '../../components/entity-manager/types';
import FalconError, {
  E_CREATE_SHARED_DEVICES,
  E_DELETE_SHARED_DEVICES,
  E_GET_SHARED_DEVICES,
  E_UPDATE_SHARED_DEVICES,
} from '../../falconeer/FalconErrors';
import sharedDeviceService from '../../services/SharedDeviceService';
import { ErrorMappingToGetCode, falconeerConst } from '../../utils/FalconeerConst';

const requiredFields = ['label', 'deviceId', 'hardwareId'];

const labelColumn: EntityDisplayAttribute = {
  attributeName: 'label',
  disablePadding: false,
  title: 'Label',
  dataType: 'string',
};
const columns: EntityDisplayAttribute[] = [
  labelColumn,
  {
    attributeName: 'device',
    disablePadding: false,
    title: 'Device Name',
    dataType: 'string',
    hideSortIcon: true,
    formatData: value => {
      return value && value.displayName ? value.displayName : '';
    },
  },
  {
    attributeName: 'appDevice',
    disablePadding: false,
    title: 'Tablet Name',
    dataType: 'string',
    hideSortIcon: true,
    formatData: value => {
      return value && value.hardwareName ? value.hardwareName : '';
    },
  },
  {
    attributeName: 'tenantId',
    disablePadding: false,
    title: 'Tenant Id',
    dataType: 'string',
    hideSortIcon: true,
  },
];

const validateSharedDevice = (payload: any) => {
  let errors = [];
  for (let i in requiredFields) {
    if (!payload[requiredFields[i]]) {
      errors.push(falconeerConst.mandatoryFieldError);
      break;
    }
  }
  return errors;
};

const manageInput = (payload: SharedDevice) => {
  if (Object.entries(payload).length > 0) {
    uiSchema.elements[1].options = { readonly: true };
    uiSchema.elements[2].options = { readonly: true };
  } else {
    uiSchema.elements[1].options = {};
    uiSchema.elements[2].options = {};
  }
};

export const getSharedDevices: DataFetcher = async (dataQuery: QueryVariables) => {
  const sharedDevice: any = await sharedDeviceService.getAll(dataQuery).catch(err => {
    throw new FalconError(E_GET_SHARED_DEVICES);
  });

  const data: ResponseData = {
    pageNumber: 1,
    recordsPerPage: sharedDevice.length,
    totalRecords: sharedDevice.length,
    totalPages: 1,
    records: sharedDevice.items,
  };

  return data;
};

const addSharedDevice: ActionHandler = async (action: string, payload: any | null): Promise<string | boolean> => {
  if (payload) {
    const createSharedDevice: CreateSharedDeviceInput = {
      deviceId: payload.deviceId,
      label: payload.label,
      data: '',
      hardwareId: payload.hardwareId,
      tenantId: payload.tenantId,
    };
    return await sharedDeviceService
      .create(createSharedDevice)
      .then(() => {
        return true;
      })
      .catch(err => {
        if (ErrorMappingToGetCode[err]) {
          return ErrorMappingToGetCode[err];
        } else {
          throw new FalconError(E_CREATE_SHARED_DEVICES);
        }
      });
  }
  return false;
};

const updateSharedDevice: ActionHandler = async (action: string, payload: any) => {
  if (payload) {
    const sharedDevice: UpdateSharedDeviceInput = {
      deviceId: payload.deviceId,
      label: payload.label,
      hardwareId: payload.hardwareId,
    };
    await sharedDeviceService.update(payload.id, sharedDevice).catch(err => {
      throw new FalconError(E_UPDATE_SHARED_DEVICES);
    });
  }
};

const deleteSharedDevice: ActionHandler = async (action: string, payload: SharedDevice[]) => {
  payload.forEach(async (sharedDevice: SharedDevice) => {
    try {
      await sharedDeviceService.delete(sharedDevice.id);
    } catch (err: any) {
      throw new FalconError(E_DELETE_SHARED_DEVICES);
    }
  });
};

const actionMap: { [key: string]: ActionHandler } = {
  add: addSharedDevice,
  update: updateSharedDevice,
  delete: deleteSharedDevice,
};

const actionHandler: ActionHandler = async (action: string, payload: any | null) => {
  if (actionMap[action]) {
    return await actionMap[action](action, payload);
  }
};

const formSchema = {
  title: 'Shared Device Management',
  description: 'Shared Device',
  type: 'object',
  required: requiredFields,
  properties: {
    label: {
      type: 'string',
      title: 'Label',
    },
    deviceId: {
      type: ['string', 'null'],
      title: 'Device',
    },
    hardwareId: {
      type: 'string',
      title: 'Hardware Id',
    },
    tenantId: {
      type: 'string',
      title: 'Tenant',
    },
  },
};

const uiSchema: any = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/label',
    },
    {
      type: 'Control',
      scope: '#/properties/deviceId',
    },
    {
      type: 'Control',
      scope: '#/properties/hardwareId',
    },
    {
      type: 'Control',
      scope: '#/properties/tenantId',
    },
  ],
};

export const sharedDeviceConfig: EntityConfig = {
  columns: columns,
  idColumnName: 'id',
  title: 'Shared Device Management',
  entityName: 'Shared Device',
  entityNamePlural: 'Shared Devices',
  actions: null,
  actionHandler: actionHandler,
  orderByColumn: labelColumn,
  orderDirection: 'asc',
  fetch: getSharedDevices,
  validatePayload: validateSharedDevice,
  disableAdd: false,
  disableEdit: false,
  disableDelete: false,
  disableDetails: false,
  disableMultiSelect: true,
  formSchema: formSchema,
  uiSchema: uiSchema,
  customActions: null,
  disableCopy: true,
  isDetailsView: false,
  manageInput: manageInput,
};
