import { UserRole } from '../api/CoreAPI';

export default class AuthUser {
  public token: string | null = null;
  constructor(
    public id: string,
    public username: string,
    public email: string,
    public phone_number: string,
    public email_verified: boolean,
    public phone_number_verified: boolean,
    public groups: UserRole[], // public clientId: string, //public token: string, // public userPoolId: string,
    public firstName: string,
    public lastName: string,
    public tenantName: string,
  ) {
    this.groups = groups as UserRole[];
  }

  isUserInGroups(groups: UserRole[]) {
    if (groups.length === 0) return false;
    return this.groups.some(group => group.includes(group));
  }
}
