export const falconeerConst = {
  appName: 'Falconeer',
  email: 'Email',
  password: 'Password',
  rePassword: 'Re-enter Password',
  newPassword: 'New Password',

  accept: 'Accept',
  reject: 'Reject',
  confirm: 'Confirm',
  back: 'Back',
  reSendCode: 'Resend Code',
  MEDICAL: 'Medical',
  HEALTH: 'Health',
  MANUFACTURER: 'Manufacturer',
  NAME: 'Name',
  MODEL: 'Model',
  SERIALNO: 'Serial Number',
  PATIENT_MEDICAL_DEVICES: 'Patient Medical Devices',
  PATIENT_HEALTH_DEVICES: 'Patient Health Devices',
  next: 'Next',
  PREV: 'Prev',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  signUp: 'Sign Up',
  resetPWD: 'Reset Password',
  forgotPassword: 'Forgot Password?',
  acceptTerms: `I have read and agree to Falconeer, Inc.'s `,

  confirmCode: 'Confirm Code',
  confirmSignup: 'Confirm Sign Up',
  confirmationCode: 'Confirmation Code',
  termsCondition: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',

  signInTitle: 'Sign In to your account',
  signUpTitle: 'Create a new account',
  resetAccountTitle: 'Reset your account password',

  enterEmail: 'Please enter your email',
  enterPWD: 'Enter your password',
  reEnterPassword: 'Re-enter your Password ',
  enterYourPassword: 'Enter your new password',
  enterPasswordLabel: 'Please enter your password',
  plusSign: '+',
  minusSign: '-',
  exponentialSign: 'e',
  capitalExponentialSign: 'E',

  signInPasswordWarn: 'Password is mandatory field.',
  enterPasswordWarn: 'Please enter password.',
  rePasswordWarn: 'Please re-enter password.',
  emailWarn: 'Please enter valid email-id.',
  emailMandatoryMessage: 'Email is mandatory field.',
  notAuthorised: 'You are not authorized to access this service. Please contact administrator.',
  notAbleToProceed: 'Unable to proceed. Please contact administrator.',
  notValidEmail: 'Please enter valid email-id.',
  enterConfirmCodeWarn: 'Please enter the confirmation code.',
  enterNewPassword: 'Please enter new password.',
  reenterNewPassword: 'Please re-enter new password.',
  authForgotWarn: 'Service not available. Please contact administrator.',
  measurementError: 'Please provide  measurement.',
  symptomError: 'Please provide  symptom.',
  questionError: 'Please provide at least one question.',
  warnCheckMail: 'Check your email for verification code.',
  passwordNotMatch: 'Entered password is not matched.',
  acceptTermsWarn: 'Please accept terms & conditions.',
  enterConfirmCode: 'Enter confirmation code',
  confrimcodeNotMacthed: 'The entered confirmation code does not match.',
  markComplete: 'Mark as Completed',
  markPending: 'Mark as Pending',
  pending: 'Pending',
  completed: 'Completed',
  alertAboveErrorMsg: 'Alert Above value should be greater than Alert Below value for:',
  UPDATE_REMARKS: 'updateRemarks',
  SELECT: 'select',
  userNotExist: 'Service not available. Please contact administrator.',

  warning: 'Warning',
  internetWarnText: 'Please check your internet connection.',
  userConfirmed: 'You have already confirmed the code.',

  noMeasurementAssigned: 'There are no measurements assigned to you yet.',
  noConditionAssigned: 'There are no conditions assigned to you yet.',
  checkEmailForAuthCode: 'Check your email for confirmation code',
  firstNameError: 'Please enter first name.',
  lastNameError: 'Please enter last name.',
  heightError: 'Please enter height in inches.',
  weightError: 'Please enter weight in lbs.',
  dateOfBirthError: 'Please enter valid date of birth.',
  dateError: 'Please enter valid date.',
  genderError: 'Please select a gender.',
  timezoneError: 'Please select a timezone.',
  addressError: 'Please enter address.',
  emailError: 'Please enter valid email.',
  emailExistsError: 'This email is already registered. Please use a different email.',
  phoneNumberError: 'Please enter valid phone number.',
  phoneNumberExistsError: 'This phone number is already registered. Please use a different phone number.',
  zipCodeError: 'Please enter zipcode.',
  cityError: 'Please enter city.',
  stateError: 'Please enter state.',
  startDateGreaterError: 'Start date should not be greater than end date.',
  Error: 'Start date should not be greater than end date.',
  conditionAcronymn: 'Please enter acronym.',
  conditionDesciption: 'Please enter description.',
  conditionName: 'Please enter condition name.',
  enterIcd10: 'Please enter icd10.',
  contentType: 'Please enter content type.',
  contentTitle: 'Please enter content title.',
  deviceType: 'Please enter device type.',
  deviceCategory: 'Please enter device category.',
  deviceManagedBy: 'Please enter device managed by.',
  deviceManfName: 'Please enter manufacturer name.',
  deviceModel: 'Please enter model name.',
  deviceName: 'Please enter device name.',
  measurementType: 'Please enter measurement type.',
  measurementDesc: 'Please enter measurement description.',
  measurementDisplayType: 'Please enter measurement display type.',
  measurementName: 'Please enter measurement name.',
  symptomName: 'Please enter symptom name.',
  unhandledError: 'Something went wrong. Please try again.',
  noCondition: 'Please assign condition to the patient.',
  ageError: 'Age should be 18 Years and above.',
  measurementStatusRisk: 'red',
  measurementStatusWarning: 'orange',
  measurementStatusActive: 'green',
  dateOfBirthLabel: 'Date of birth (MM/DD/YYYY) (Age should be 18 Years and above)',
  assessmentProvocation: 'Provocation',
  assessmentQuality: 'Quality',
  assessmentRegion: 'Onset',
  assessmentSeverity: 'Severity',
  assessmentTiming: 'Timing',
  assessmentPalliation: 'Palliation',
  remove: 'Remove',
  add: 'Add',
  details: 'Details',
  BLANK: '',
  PrescribedDeviceCreationErr: 'Error in creating patient prescribed device.',
  infoIcon: 'Info',
  measurements: 'Measurements:',
  prescribe: 'Prescribe',
  prescribed: 'Prescribed',
  unprescribe: 'Unprescribe',
  recommendedDevices: 'Recommended medical devices',
  noDeviceFound: 'No devices found',
  timeSpentThisMonth: 'Time spent in this month',
  mandatoryFieldError: 'Please fill all the mandatory fields.',
  noTargetsAssigned: 'No targets assigned',
  noMeasurementsFound: 'No measurements found',
  selectMeasurements: 'Select measurements',
  noConditionsFound: 'No conditions found',
  patientConditionError: 'Unable to fetch patient conditions',
  prescribedDeviceError: 'Unable to fetch prescribed devices',
  createConditionError: 'Error while creating patient condition',
  deleteConditionError: 'Error while deleting patient condition',
  notAssigned: 'Not assigned',
  occuredAt: 'Occured At: ',
  reportedOn: 'Reported On: ',
  reportedBy: 'Reported By: ',
  self: 'Self',
  noDocumentFound: 'No documents found',
  unSavedContent:
    'You have unsaved data.\nYou can discard the changes and move to another page or stay on the same page.',
  confirmTimezoneData: 'Please note that the time zone cannot be changed once the patient is imported.',
  patientName: 'Patient Name',
  address: 'Address',
  assignDeviceName: 'Device Name',
  assignDeviceError: 'Please Enter Device Id',
  assign: 'Assign',
  assigned: 'Assigned',
  search: 'Search...',
  searchLabel: 'Search',
  CONFIRMATION: 'Confirmation',
  LOGOUT: 'Logout',
  CANCEL: 'Cancel',
  LOGOUT_MESSAGE: 'Are you sure you want to logout?',
  ALERTS_TITLE: 'Alerts',
  GENERATE: 'Generate',
  COPY_TO_CLIPBOARD: 'Copy to Clipboard',
  PHONE_EMAIL_ERROR: 'Please enter either email or phone number or both.',
  PATIENT_CONDITION_MESSAGE: 'Patient should have at least one condition assigned.',
  WITHINGS: 'Withings',
  PATIENT_ROLE_ID: 'd3bde77d-ec4a-4f56-85ec-1ca0f47b2e4f',
  CAREGIVER_ROLE_ID: '7adc0dab-05fa-49ed-aed7-9e63ee7be31e',
  measurementBucketType: 'Please enter measurement bucket type.',
  ADD_COUNTRY: 'Please enter country.',
  DEFAULT_COUNTRY: 'USA',
  DEFAULT_COUNTRY_CODE: '+1',
  CHANGE: 'Change',
  TOTAL: 'Total',
  AVERAGE: 'Average',
  CLOSE: 'Close',
  NO_Data: 'No Data',
  ONE_DAY: '1 Day',
  SEVEN_DAYS: '7 Days',
  THIRTY_DAYS: '30 Days',
  ONE_YEAR: '1 Year',
  STROKE_COLOR_LINE_1: '#8884d8',
  STROKE_COLOR_LINE_2: '#DB7B2B',
  TENOVI: 'Tenovi',
  PATIENT_ID: 'Patient ID',
  ALLOW_PATIENT_SIGNUP: 'Allow patient to signup',
  BP_SYSTOLIC: 'bloodpressure.systolic',
  BP_DIASTOLIC: 'bloodpressure.diastolic',
  requiredFieldError: 'is a required property',
};

export const ERR_CODE = {
  InvalidParam: 'InvalidParameterException',
  LimitExceed: 'LimitExceededException',
  InvalidEmail: '6037',
  NotAuthorized: '6038',
  InvalidResetPwdCode: '6030',
  InvalidSignUpPwdCode: '6033',
  UserNotFound: 'UserNotFoundException',
  UserLamdaExc: 'UserLambdaValidationException',
  UserNotConfirmed: 'UserNotConfirmedException',
  UsernameExist: 'UsernameExistsException',
  ResourceNotFoundException: 'ResourceNotFoundException',
  UserNotConfirmedException: '6045',
  UserSignUpRequired: '6044',
  Unkown: 'unkown',
  UserConfrimed: 'userConfirmed',
  UnAuthorizedUser: 'unAuthorisedUser',
  ExpiredCodeException: 'ExpiredCodeException',
  CodeMismatchException: 'CodeMismatchException',
  PRE_AUTH_FAILED: 'UserLambdaValidationException',
};

export const getAuthErrorMessage = (statuscode: string) => {
  switch (statuscode) {
    case '404':
      return `You do not have access to this service. Please contact your doctor.`;
    case '6037':
      return `You do not have access to this service. Please contact the administrator.`;
    case '6038':
      return `Please enter the correct password.`;
    case '6030':
      return `Please enter valid code.`;
    case '6033':
      return `Please enter valid code.`;
    case '6027':
      return 'User is not allowed to login from this source';
    case 'AliasExistsException':
      return `This email already exists.`;
    case 'UserNotFoundException':
      return `This email is not found.`;
    case 'InvalidPasswordException':
      return `Please enter a strong password.`;
    case 'UsernameExistsException':
      return `You've already signed up with this email.`;
    case 'InvalidParameterException':
      return `You've already confirmed the code.`;
    case 'userConfirmed':
      return `You've already confirmed the code.`;
    case 'LimitExceededException':
      return `You have exceeded the number of attempts. Please try after 15 minutes.`;
    case 'UserNotConfirmedException':
      return `Please complete the code confirmation.`;
    case 'unAuthorisedUser':
      return `The sign-up confirmation failed.`;
    case 'ERR_NETWORK':
      return `Your internet connection is unstable. please check your network stability.`;
    case 'ExpiredCodeException':
      return `Please enter valid code`;
    case 'CodeMismatchException':
      return `Please enter valid code`;

    case '500':
      return `Internal Service is not available. please try after some time.`;
    default:
      return ``;
  }
};

export const ErrorMappingToGetCode: Record<string, string> = {
  6098: 'This email is already used.',
  6099: 'This phone number is already used.',
  6100: 'User email is already invited.',
  6101: 'User phone is already invited.',
  6090: 'Withings activation failed',
  6096: 'Device id is invalid',
  6097: 'Height or weight is invalid. The weight must be between 1 and 600kg and the height between 0.1 and 3m',
  6106: 'Shared device already exist',
  6107: 'Device already assigned to a patient',
  6108: 'Device already assigned as a shared device',
  6122: 'Tenovi device not found',
};
