import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Checkbox,
  Avatar,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { TableColumn, EntityConfig, ActionHandler, ResponseData, QueryVariables, SearchFilter, Filter } from './types';

import { EntityTableHead } from './EntityTableHead';
import { getComparator, getPageRange, Order, stableSort } from './utils';
import { ModeEditOutlineRounded } from '@mui/icons-material';
import { ContentCopyOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { useMessageModalContext } from '../dialog/MessageContextProvider';
import { FTableRow } from '../../components/fcomponents/FTableRow';
import { EntityTableToolbar } from './EntityTableToolbar';
import { FPrimaryButton } from '../fcomponents';
import { falconeerConst } from '../../utils/FalconeerConst';
import Person2Icon from '@mui/icons-material/Person2';
import OverlayLoader from '../OverlayLoader';
import './EntityTable.css';

type EntityTableProps = {
  config: EntityConfig;
  actionHandler: ActionHandler;
};

const getAvatar = (url: string) =>
  url ? (
    <Avatar sx={{ width: 50, height: 50 }} src={url}>
      A
    </Avatar>
  ) : (
    <AddCircleOutlineRounded />
  );

export const EntityTable: React.FC<EntityTableProps> = (props: EntityTableProps) => {
  const [orderDirection, setOrder] = React.useState<Order>(
    props.config.orderDirection ? props.config.orderDirection : 'asc',
  );
  const [orderByColumn, setOrderBy] = React.useState<TableColumn>(props.config.orderByColumn);
  const [selectedRowIds, setSelected] = React.useState<readonly string[]>([]);
  const [selectedPage, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  // const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(20); // Default Pagination
  const [recordsPerPage, setRecordsPerPage] = React.useState(20); // showNewPagination true
  const [searchedText, setSearchedText] = React.useState('');
  const [currentRecordsDisplay, setCurrentRecordsDisplay] = React.useState('');
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const dialogContext = useMessageModalContext();
  const [responseData, setResponseData] = React.useState<ResponseData>({
    pageNumber: 1,
    recordsPerPage: 0,
    totalPages: 0,
    totalRecords: 0,
    records: [],
  });
  const [lastUpdate, setLastUpdate] = React.useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getAllRecords = (dataQuery: QueryVariables) => {
      props.config
        .fetch(dataQuery)
        .then(responseData => {
          setResponseData(responseData);
          if (props.config.showNewPagination) {
            const pageRange = getPageRange(responseData.records.length, recordsPerPage, currentPage);
            setCurrentRecordsDisplay(`1-${pageRange}`);
            setIsLastPage(responseData.records.length < rowsPerPage);
          }
        })
        .catch(err => {
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const getFilterString = (searchText: string) => {
      let datatoReturn: SearchFilter = {};
      if (searchText) {
        const columnstoFilter = props.config.columns.filter(x => x.disableSearch !== true && x.dataType === 'string');
        if (columnstoFilter && columnstoFilter.length > 0) {
          const orData: Filter[] = [];
          columnstoFilter.forEach(column => {
            orData.push({ [column.attributeName]: { contains: searchText } });
          });
          datatoReturn.or = orData;
        }
      }
      return datatoReturn;
    };

    const dataQuery: QueryVariables = {
      pageNo: selectedPage,
      pageSize: rowsPerPage,
      orderBy: orderByColumn.attributeName,
      orderDirection: orderDirection,
      filter: getFilterString(searchText),
      sort: props.config.customSort
        ? props.config.customSort({ field: orderByColumn.attributeName, direction: orderDirection.toUpperCase() })
        : [{ field: orderByColumn.attributeName, direction: orderDirection.toUpperCase() }],
    };
    setTimeout(() => {
      getAllRecords(dataQuery);
    }, 50);
  }, [
    rowsPerPage,
    selectedPage,
    orderByColumn.attributeName,
    orderDirection,
    props.config,
    lastUpdate,
    searchText,
    props.config.refreshEntityData,
  ]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: TableColumn) => {
    const isAsc = orderByColumn.attributeName === property.attributeName && orderDirection === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  props.config.handleRequestSort = handleRequestSort;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (responseData.records && event.target.checked) {
      const newSelecteds = responseData.records.map(n => n[props.config.idColumnName]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (!props.config.disableMultiSelect) {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRowIds.slice(0, selectedIndex),
          selectedRowIds.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    } else {
      props.actionHandler('select', id);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actionHandler: ActionHandler = async (action: string, payload: any | null) => {
    console.log('EntityTable: actionHandler called with action: ', action);
    try {
      switch (action) {
        case 'add':
          setLoading(true);
          await props.actionHandler(action, null);
          setLoading(false);
          break;
        case 'copy':
          await props.actionHandler(action, payload);
          break;
        case 'edit':
          setLoading(true);
          await props.actionHandler(action, payload);
          setLoading(false);
          break;
        case 'details':
          await props.actionHandler(action, payload);
          break;
        case 'delete':
          await props.actionHandler(action, payload);

          break;
        case 'search':
          setSearchText(payload);
          break;
        case 'refresh':
          setCurrentPage(1);
          setRowsPerPage(20);
          setRecordsPerPage(20);
          // No special treatment needed
          // Call setLastUpdate at the end of the function and it will refresh data anyway
          break;
      }
    } catch (err: any) {
      console.log('Error in actionHandler: ', err);
      dialogContext.error('Error while performing action ' + action + ': ' + err.message);
    }
    console.log('EntityTable: actionHandler setting last update date ');
    setLastUpdate(new Date());
    setSearchedText('');
    setSelected([]);
  };

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDense(event.target.checked);
  // };

  const isselected = (id: string) => selectedRowIds.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    selectedPage > 0
      ? Math.max(0, (1 + selectedPage) * rowsPerPage - (responseData ? responseData.records.length : 0))
      : 0;
  const isDetailView = (event: any, row: any) => {
    if (props.config.disableDetails) {
      handleClick(event, row[props.config.idColumnName]);
    } else {
      props.actionHandler('details', row);
    }
  };

  const changeRowsPerPage = (e: any) => {
    setRecordsPerPage(e.target.value);
    if (props?.config?.search) {
      const response: any = props.config.search(searchedText, 1, e.target.value);
      response.then((data: any) => {
        setResponseData(data);
        const pageRange = getPageRange(data.totalRecords, e.target.value, currentPage);
        setCurrentRecordsDisplay(`1-${pageRange}`);
        setIsLastPage(data.totalRecords < e.target.value);
        setCurrentPage(1);
      });
    }
  };

  const pageHandler = (status: number) => {
    if (props?.config?.search) {
      const newPage = currentPage + status;
      const response: any = props.config.search(searchedText, newPage, recordsPerPage);
      response.then((data: any) => {
        setResponseData(data);
        setCurrentPage(newPage);
        const totalRecords = data.totalRecords;
        const calRecords = recordsPerPage * newPage;
        const end = totalRecords < calRecords ? totalRecords : calRecords;
        const start = recordsPerPage * (newPage - 1) + 1;
        setIsLastPage(totalRecords < calRecords);
        setCurrentRecordsDisplay(`${start}-${end}`);
      });
    }
  };
  return (
    <>
      {<OverlayLoader isLoading={loading}></OverlayLoader>}
      <EntityTableToolbar
        disableMultiSelect={props.config.disableMultiSelect}
        disableAdd={props.config.disableAdd}
        disableDelete={props.config.disableDelete}
        customActions={props.config.customActions}
        addTooltip={`Add ${props.config.entityName}`}
        deleteTooltip={`Delete ${props.config.entityName}`}
        numSelected={selectedRowIds.length}
        actionHandler={actionHandler}
        title={props.config.entityNamePlural}
        entitySearch={props.config.search}
        setResponse={setResponseData}
        searchedText={searchedText}
        setSearchText={setSearchedText}
        showSearch={props.config?.showSearch}
        recordsPerPage={recordsPerPage}
        setCurrentRecordsDisplay={setCurrentRecordsDisplay}
        currentPage={currentPage}
        setIsLastPage={setIsLastPage}
        setCurrentPage={setCurrentPage}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750, borderCollapse: 'separate', borderSpacing: '0px 10px' }}
          aria-labelledby='tableTitle'
          size='small'>
          <EntityTableHead
            columns={props.config.columns}
            numSelected={selectedRowIds.length}
            order={orderDirection}
            orderBy={orderByColumn}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={responseData.records.length}
            mainConfig={props.config}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {(!responseData || !responseData.records || responseData.records.length == 0) && !loading && (
              <FTableRow>
                <TableCell colSpan={props.config.columns.length + 1} align='center'>
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{ flex: '1 1 100%' }}
                      color='inherit'
                      variant='subtitle1'
                      component='h6'
                      alignItems='center'
                      justifyContent='center'
                      marginBottom='0px'>
                      No data available.
                      {!props.config.disableAdd && (
                        <>
                          <span className='left-margin'></span>Please add one.
                          <FPrimaryButton onClick={() => props.actionHandler('add', null)} variant='outlined'>
                            Add
                          </FPrimaryButton>
                        </>
                      )}
                    </Typography>
                  </Box>
                </TableCell>
              </FTableRow>
            )}
            {responseData &&
              stableSort(responseData.records, getComparator(orderDirection, orderByColumn))
                .slice(
                  props.config.showNewPagination ? 0 : selectedPage * rowsPerPage,
                  props.config.showNewPagination
                    ? responseData.records.length
                    : selectedPage * rowsPerPage + rowsPerPage,
                )
                .map((row, index) => {
                  const isItemSelected = isselected(row[props.config.idColumnName]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let mergedObject = row;
                  if (props.config?.getFormattedRow) {
                    mergedObject = props.config?.getFormattedRow(row);
                  }

                  return (
                    <FTableRow
                      hover
                      sx={{ borderRadius: '50%', fontWeight: 'medium' }}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[props.config.idColumnName]}
                      selected={isItemSelected}>
                      {!props?.config?.disableMultiSelect && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      {props.config.columns.map((column, index: number) => {
                        let cellValue;
                        const arrayInfo = column.attributeName.split('.');
                        let currentValue = row;
                        if (currentValue) {
                          for (let index = 0; index <= arrayInfo.length - 1; index++) {
                            currentValue = currentValue[arrayInfo[index]];
                          }
                          if (column.attributeName === 'avatar') {
                            cellValue = getAvatar(currentValue);
                          } else if (column.formatData) {
                            cellValue = column.formatData(currentValue);
                          } else if (column.getComp) {
                            cellValue = column.getComp(currentValue);
                          } else if (column.getLetters) {
                            cellValue = column.getLetters(row.name ? row.name : `${row.firstName} ${row.lastName}`);
                          } else if (column.getDataFromRow) {
                            cellValue = column.getDataFromRow(row);
                          } else {
                            cellValue = currentValue;
                          }
                        }
                        let finalResult;
                        if (props.config.drillDownColumn === column) {
                          finalResult = (
                            <TableCell
                              key={index}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: '#030A73',
                              }}
                              align={column.align ? column.align : 'left'}
                              onClick={event => isDetailView(event, row)}>
                              {cellValue}
                            </TableCell>
                          );
                        } else if (props.config.refreshIconColumn === column) {
                          finalResult = (
                            <TableCell
                              key={index}
                              align={column.align ? column.align : 'left'}
                              onClick={event => props.actionHandler(falconeerConst.UPDATE_REMARKS, row)}>
                              {cellValue}
                            </TableCell>
                          );
                        } else if (props.config.infoIconColumn === column) {
                          finalResult = (
                            <TableCell
                              key={index}
                              align={column.align ? column.align : 'left'}
                              onClick={event => props.actionHandler(falconeerConst.UPDATE_REMARKS, row)}>
                              {cellValue}
                            </TableCell>
                          );
                        } else {
                          finalResult = (
                            <TableCell key={index} align={column.align ? column.align : 'left'}>
                              {column.tooltip && (
                                <Tooltip title={column.tooltip(row)} placement='bottom' arrow>
                                  {cellValue}
                                </Tooltip>
                              )}
                              {!column.tooltip && cellValue}
                            </TableCell>
                          );
                        }
                        return finalResult;
                      })}
                      <TableCell align='right' key={index}>
                        {props.config.customIconHandler &&
                          props.config.customIconHandler.map((action, id) => (
                            <>
                              <IconButton size='small'>{action.customIcon(row, index, id)}</IconButton>
                            </>
                          ))}
                        {props.config.disableEdit && (
                          <IconButton size='small' sx={{ visibility: 'hidden' }}>
                            <Person2Icon />
                          </IconButton>
                        )}

                        {!props.config.disableEdit && (
                          <Tooltip title={'Edit'}>
                            <IconButton
                              size='small'
                              color='inherit'
                              onClick={() => props.actionHandler('edit', mergedObject)}>
                              <ModeEditOutlineRounded />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!props.config.disableAdd && !props.config.disableCopy && (
                          <Tooltip title={'Copy'}>
                            <IconButton
                              size='small'
                              color='inherit'
                              onClick={() => {
                                props.actionHandler('copy', row);
                              }}>
                              <ContentCopyOutlined />
                            </IconButton>
                          </Tooltip>
                        )}

                        {!props.config.disableDelete && (
                          <Tooltip title={'Delete'}>
                            <IconButton onClick={() => actionHandler('delete', [row])}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {props.config.rowAction &&
                          props.config
                            .rowAction(row)
                            .map((x, index) => <Button onClick={() => x.actionHandler(row)}>{x.title}</Button>)}
                      </TableCell>
                    </FTableRow>
                  );
                })}
            {emptyRows > 0 && (
              <FTableRow
                style={{
                  // height: (dense ? 33 : 53) * emptyRows,
                  height: 33 * emptyRows,
                }}></FTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.config.showNewPagination ? (
        <div className='pagination'>
          <div className='page-info-label'>Rows per page:</div>
          <div className='page-info-detail'>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={recordsPerPage}
              variant='standard'
              disableUnderline
              onChange={e => changeRowsPerPage(e)}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>
          <div className='records-info'>
            {currentRecordsDisplay} of {responseData.totalRecords}
          </div>
          <button
            disabled={currentPage === 1}
            className={currentPage === 1 ? 'previous-page disabled' : 'previous-page'}
            onClick={() => pageHandler(-1)}>
            <ArrowBackIosIcon />
          </button>
          <button
            disabled={isLastPage}
            className={isLastPage ? 'next-page disabled' : 'next-page'}
            onClick={() => pageHandler(1)}>
            <ArrowForwardIosIcon />
          </button>
        </div>
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25, 50]}
          component='div'
          count={responseData.records.length}
          rowsPerPage={rowsPerPage}
          page={selectedPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default EntityTable;
