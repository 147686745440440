import { PatientGender, RelationType, Tenant, UserRole } from './CoreAPI';

export enum SummaryType {
  raw = 'raw',
  daily = 'daily',
  monthly = 'monthly',
  hourly = 'hourly',
  yearly = 'yearly',
  latest = 'latest',
}

export enum PrescribedDeviceStatus {
  prescribed = 'Prescribed',
  ordered = 'Ordered',
  shipped = 'Shipped',
  received_by_patient = 'Received',
}

export const CURRENT_APP_VERSION = 0;

export const PROVIDER_WEB_APP_ID = 6;

export type MeasurementData = {
  date: string;
  min: number;
  max: number;
  avg: number;
};

export type MeasurementDataV1 = {
  measurementSummary: any;
  key: string;
  values: specs[];
  data: Data[];
};

export type Data = {
  data: any;
};

export type specs = {
  time?: string;
  min: string;
  max: string;
  avg: string;
  value?: string;
};

export type HealthDataRequest = {
  measurementNames: string[];
  startDate?: Date;
  endDate?: Date;
  patientId: string;
  summary?: SummaryType;
  limit?: number;
};
export type ValuesType = {
  time: string;
  total?: number;
  avg?: number;
  value?: number;
};

export type HealthDataRecords = {
  key: string;
  values: ValuesType[];
};

export type HealthDataReportRequest = {
  measurementNames: string[];
  startDate?: Date;
  endDate?: Date;
  patientId: string;
  isGetPatientReportedEvents: boolean;
  isGetAlerts: boolean;
  isPreviousDataNeeded: boolean;
};

export type MeasurementSummary = {
  activitySummary: MeasurementSummaryDetails[];
  vitalSummary: MeasurementSummaryDetails[];
};

export type MeasurementSummaryDetails = {
  displayName: string;
  unit: string;
  mName: string;
  min: number;
  max: number;
  avg: number;
  count: number;
  previosValue: number;
  changeValue: number;
  pctChange: string | number;
};

export type AlertsSummary = {
  eventType: string;
  displayName: string;
  eventData: string;
  eventParameter: string;
  count: number;
};

export type SymptomSummary = {
  name: string;
  displayName: string;
  id: string;
  count: number;
  maxSeverity: number;
  minSeverity: number;
  avgSeverity: number;
};

export type PreviousDate = {
  previousStartDate: Date;
  previousEndDate: Date;
};

export type ReportData = {
  measurementSummary?: MeasurementSummary;
  alertsSummary?: AlertsSummary[];
  symptomSummary?: SymptomSummary[];
  previousDate?: PreviousDate;
};

export enum Aggregate {
  avg = 'avg',
  min = 'min',
  max = 'max',
  total = 'total',
}

export type PatientDataResponse = {
  key: string;
  values: MeasurementData[];
};
export type HealthDataResult = {
  nextToken: string | null;
  data: MeasurementDataV1;
};
export type HealthDataErrortype = {
  errorCode: number;
  message: string;
};
export type HealthDataResponse = {
  status: string;
  data: HealthDataResult;
  error: HealthDataErrortype | null;
};

export type Documentation = {
  __typename: 'Documentation';
  id: string;
  notes: string;
  userId: string;
  user?: any | null;
  capturedById: string;
  capturedByUserName?: string;
  reviewededByUserName?: string;
  capturedDate: string;
  isReviewed: boolean;
  reviewedById?: string | null;
  reviewedOn?: string | null;
  capturedBy?: any | null;
  reviewedBy?: any | null;
  createdAt: string;
  updatedAt: string;
};

export type InvitedUserPayload = {
  address?: string;
  birthDate?: Date;
  city?: string;
  createdAt: Date;
  data?: any;
  email: string;
  firstName: string;
  gender: PatientGender;
  height?: number;
  id: string;
  invitedByUserId?: string;
  joinedAt?: Date;
  lastName: string;
  phoneNumber?: string;
  relation?: RelationType;
  role: UserRole;
  state?: string;
  tenant: Tenant;
  tenantId: string;
  timezone?: string;
  updatedAt?: Date;
  weight?: number;
  zipCode?: string;
  displayName: string;
  roleId: string;
};
