export const E_UNKNOWN = 4100;
export const E_INVALID_INPUT = 4101;

export const E_STORE_INIT = 5000;
export const E_DELETE_USER_WITH_DEVICES = 5001;
export const E_USER_PROFILE_FETCH = 5002;
export const E_USER_INFO_FETCH = 5003;
export const E_USER_NOT_LOGGEDIN = 5004;
export const E_DEVICE_CREATE = 5005;
export const E_DEVICE_FIRMWRAE_UPDATES_CREATE = 5006;
export const E_NO_MODEL = 5007;
export const E_INVALID_MODEL = 5008;
export const E_INVALID_TOKEN = 6012;
export const E_EXPIRED_TOKEN = 8001;
export const E_TS_CONFIG = 5107;
export const E_TS_WRITE = 5108;
export const E_TS_READ = 5109;
export const E_API_ERROR = 5110;
export const E_CONFIG_ERROR = 5124;
export const E_UNAUTHORIZED_ERROR = 401;
export const E_CREATE_PATIENT = 5009;
export const E_GET_TASKS = 6001;
export const E_GET_SEARCH_TASKS = 6011;
export const E_UPDATE_TASKS = 6002;
export const E_PATIENT_DETAILS = 6003;
export const E_GET_MEASUREMENT = 6050;
export const E_UPDATE_MEASUREMENT = 6051;
export const E_CREATE_MEASUREMENT = 6052;
export const E_FETCH_PATIENT_CONDITIONS = 6004;
export const E_CREATE_PATIENT_CONDITIONS = 6005;
export const E_DELETE_PATIENT_CONDITIONS = 6010;
export const E_FETCH_PATIENT_SYMPTOMS = 6006;
export const E_FETCH_PATIENT_DOCUMENTATIONS = 6007;
export const E_CREATE_PATIENT_TIMER_DATA = 6008;
export const E_FETCH_PATIENT_TIMER_DATA = 6009;

export const E_CREATE_SYMPTOM = 7000;
export const E_UPDATE_SYMPTOM = 7001;
export const E_DELETE_SYMPTOM = 7002;
export const E_FETCH_SYMPTOM = 7003;
export const E_GET_CONTENT = 7050;
export const E_CREATE_CONTENT = 7051;
export const E_UPDATE_CONTENT = 7052;
export const E_GET_PATIENT_DETAILS = 7053;
export const E_UPDATE_PATIENT_DETAILS = 7054;
export const E_GET_PRESCRIBED_DEVICE = 6102;
export const E_UPDATE_PRESCRIBED_DEVICE = 6103;
export const E_GET_PATIENT = 7060;
export const E_UPDATE_PATIENT = 7061;
export const E_GET_SEARCH_PATIENT = 7062;
export const E_GET_PATIENT_MEASUREMENT = 6200;
export const E_GET_PATIENT_DEVICE = 6201;
export const E_GET_HEALTHDATA = 6202;
export const E_GET_TENANT_LIST = 11101;
export const E_CREATE_DEVICE = 9001;
export const E_UPDATE_DEVICE = 9002;
export const E_FETCH_DEVICE = 9003;
export const E_FETCH_CONDITION = 8005;
export const E_CREATE_CONDITION = 8002;
export const E_UPDATE_CONDITION = 8003;
export const E_CDS_ERROR = 9050;
export const E_CREATE_DOCUMENTATION = 9060;
export const E_UPDATE_DOCUMENTATION = 9061;
export const E_CREATE_PATIENT_DEVICE = 9062;
export const E_WITHINGS_ACTIVATION_FAILED = 6090;
export const E_MAC_ADDRESS_ERROR = 6096;
export const E_HEIGHT_WEIGHT_INVALID = 6097;
export const E_IMPORT_PATIENT_EMAIL_ERROR = 6098;
export const E_IMPORT_PATIENT_PHONE_ERROR = 6099;
export const E_IMPORT_PATIENT_EMAIL_EXIST_IN_INVITED_ERROR = 6100;
export const E_IMPORT_PATIENT_PHONE_EXIST_IN_INVITED_ERROR = 6101;
export const E_CONFIG_MISSING = 6109;
export const E_GET_SEARCH_USER = 7069;
export const E_FETCH_ONBOARD_USER = 7021;
export const E_CREATE_ONBOARD_USER = 7022;
export const E_UPDATE_ONBOARD_USER = 7023;
export const E_DELETE_ONBOARD_USER = 7024;
export const E_SEARCH_ONBOARD_USER = 7025;
export const E_GET_ONBOARD_USER = 6056;
export const E_GET_Template = 7057;
export const E_COPY_DATA_API_FAILED = 7058;
export const E_COPY_DATA_TEMPLATE_EMPTY = 7059;
export const E_GET_SHARED_DEVICES = 7150;
export const E_UPDATE_SHARED_DEVICES = 7151;
export const E_DELETE_SHARED_DEVICES = 7152;
export const E_CREATE_SHARED_DEVICES = 7153;
export const E_CREATE_PRESCRIBED_DEVICE = 7154;

type ErrorMessage = {
  [errorCode: number]: string;
};

export const errorMessages: ErrorMessage = {
  [E_UNKNOWN]: 'Something went wrong',
  [E_STORE_INIT]: 'Can not load user profile.',
  [E_DELETE_USER_WITH_DEVICES]: 'Can not delete user with assigned devices. Please delete devices first.',
  [E_USER_PROFILE_FETCH]: 'Error in fetching user profile.',
  [E_USER_INFO_FETCH]: 'Error in fetching user profile.',
  [E_USER_NOT_LOGGEDIN]: 'User is not logged in.',
  [E_DEVICE_CREATE]: 'Unable to create device.',
  [E_DEVICE_FIRMWRAE_UPDATES_CREATE]: 'Unable to create device firmware.',
  [E_TS_CONFIG]: 'Invalid timeseries configuration.',
  [E_TS_WRITE]: 'Unable to write timeseries data.',
  [E_TS_READ]: 'Unable to read timeseries data.',
  [E_INVALID_INPUT]: 'Invalid inputs',
  [E_NO_MODEL]: 'Unable to find device model in the backend.',
  [E_INVALID_MODEL]: 'Internal error. Firmawre details are missing from model. Please contact administrator.',
  [E_API_ERROR]: 'API error',
  [E_CONFIG_ERROR]: 'Error in fetching configurations.',
  [E_INVALID_TOKEN]: 'Invalid user token.',
  [E_CREATE_PATIENT]: 'Unable to create imported patient',
  [E_CREATE_SYMPTOM]: 'Unable to create symptoms',
  [E_UPDATE_SYMPTOM]: 'Unable to update symptoms',
  [E_DELETE_SYMPTOM]: 'Unable to delete symptoms',
  [E_CREATE_DEVICE]: 'Unable to create device',
  [E_UPDATE_DEVICE]: 'Unable to update device',
  [E_GET_TASKS]: 'Unable to fetch tasks',
  [E_GET_SEARCH_TASKS]: 'Unable to search tasks',
  [E_UPDATE_TASKS]: 'Unable to update tasks',
  [E_PATIENT_DETAILS]: 'Unable to get patient details',
  [E_GET_CONTENT]: 'Unable to fetch contents',
  [E_CREATE_CONTENT]: 'Unable to create contents',
  [E_UPDATE_CONTENT]: 'Unable to update contents',
  [E_GET_PATIENT_DETAILS]: 'Unable to fetch imported patients',
  [E_UPDATE_PATIENT_DETAILS]: 'Unable to update imported patients',
  [E_GET_MEASUREMENT]: 'Unable to fetch measurements',
  [E_GET_ONBOARD_USER]: 'Unable to fetch onboard users',
  [E_UPDATE_MEASUREMENT]: 'Unable to update measurement',
  [E_CREATE_MEASUREMENT]: 'Unable to create measurement',
  [E_GET_PRESCRIBED_DEVICE]: 'Unable to fetch prescribed devices',
  [E_UPDATE_PRESCRIBED_DEVICE]: 'Unable to update prescribed devices',
  [E_GET_PATIENT]: 'Unable to fetch patients',
  [E_GET_SEARCH_PATIENT]: 'Unable to search patients',
  [E_GET_SEARCH_USER]: 'Unable to search imported patients',
  [E_UPDATE_PATIENT]: 'Unable to update patient',
  [E_GET_PATIENT_MEASUREMENT]: 'Unable to fetch patient measurements',
  [E_GET_PATIENT_DEVICE]: 'Unable to fetch patient devices',
  [E_GET_HEALTHDATA]: 'Unable to fetch patient health data',
  [E_FETCH_CONDITION]: 'Unable to fetch conditions',
  [E_CREATE_CONDITION]: 'Unable to create conditions',
  [E_UPDATE_CONDITION]: 'Unable to update conditions',
  [E_FETCH_PATIENT_CONDITIONS]: 'Unable to fetch patient conditions',
  [E_CREATE_PATIENT_CONDITIONS]: 'Unable to create patient condition',
  [E_DELETE_PATIENT_CONDITIONS]: 'Unable to delete patient condition',
  [E_FETCH_PATIENT_SYMPTOMS]: 'Unable to fetch patient symptom',
  [E_FETCH_PATIENT_DOCUMENTATIONS]: 'Unable to fetch patient documentations',
  [E_CREATE_PATIENT_TIMER_DATA]: 'Unable to create patient timer data',
  [E_FETCH_PATIENT_TIMER_DATA]: 'Unable to fetch patient timer data',
  [E_FETCH_SYMPTOM]: 'Unable to fetch symptoms',
  [E_CDS_ERROR]: 'Unable to fetch alerts',
  [E_FETCH_DEVICE]: 'Unable to fetch devices',
  [E_CREATE_DOCUMENTATION]: 'Unable to create patient documentations',
  [E_UPDATE_DOCUMENTATION]: 'Unable to update patient documentation',
  [E_CREATE_PATIENT_DEVICE]: 'Unable to create patient device',
  [E_MAC_ADDRESS_ERROR]: 'Device id is invalid',
  [E_HEIGHT_WEIGHT_INVALID]:
    'Height or weight is invalid. The weight must be between 1 and 600kg and the height between 0.1 and 3m',
  [E_WITHINGS_ACTIVATION_FAILED]: 'Withings activation failed',
  [E_CONFIG_MISSING]: 'Something went wrong, we are not able to configure app',
  [E_GET_Template]: 'Unable to fetch template',
  [E_COPY_DATA_API_FAILED]: 'Copy data API failed',
  [E_COPY_DATA_TEMPLATE_EMPTY]: 'No template found',
  [E_GET_SHARED_DEVICES]: 'Unable to fetch shared device',
  [E_UPDATE_SHARED_DEVICES]: 'Unable to update shared device',
  [E_CREATE_SHARED_DEVICES]: 'Unable to create shared device',
  [E_DELETE_SHARED_DEVICES]: 'Unable to delete shared device',
  [E_CREATE_PRESCRIBED_DEVICE]: 'Unable to create prescribed device',
  [E_FETCH_ONBOARD_USER]: 'Unable to fetch Onboard Users',
  [E_CREATE_ONBOARD_USER]: 'Unable to onboard a user',
  [E_UPDATE_ONBOARD_USER]: 'Unable to update a user',
  [E_DELETE_ONBOARD_USER]: 'Unable to delete a user',
  [E_SEARCH_ONBOARD_USER]: 'Unable to search onboard users',
  [E_GET_TENANT_LIST]: 'Unable to fetch tenant list',
};

export default class FalconError extends Error {
  constructor(
    public errorCode: number,
    public extraMessage: string | null = null,
    public showExtraMessage: boolean = false,
  ) {
    console.log('errorCode', errorCode);
    console.log(errorMessages[errorCode]);
    let errorMessage: string =
      (errorMessages[errorCode] || errorMessages[E_UNKNOWN]) + (extraMessage ? ' : ' + extraMessage : '');
    if (showExtraMessage) {
      errorMessage = extraMessage as string;
    }
    super(errorMessage);
  }
}
