import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ActionButton, getPageRange } from './utils';
import {
  CloseIconWrapper,
  SearchBoxWrapper,
  StyledSearch,
  SearchIconWrapper,
  StyledInputBase,
} from '../../styles/CommonStyles';
import { falconeerConst } from '../../utils/FalconeerConst';

interface SearchProps {
  search: Function | undefined;
  setResponseData: Function;
  setSearchData: Function;
  disabled?: boolean;
  searchedText: string;
  recordsPerPage: number;
  setCurrentRecordsDisplay: Function;
  setIsLastPage: Function;
  setCurrentPage: Function;
  currentPage: number;
}

export const Search = (props: SearchProps) => {
  const searchData = () => {
    const recordsPerPage = props.recordsPerPage;
    if (props.search) {
      const response: any = props.search(props.searchedText, 1, recordsPerPage);
      response.then((data: any) => {
        props.setResponseData(data);
        const totalRecords = data.totalRecords;
        const pageRange = getPageRange(totalRecords, recordsPerPage, props.currentPage);
        props.setCurrentRecordsDisplay(`1-${pageRange}`);
        props.setIsLastPage(totalRecords < recordsPerPage);
        props.setCurrentPage(1);
      });
    }
  };

  const clearSearchData = () => {
    const recordsPerPage = props.recordsPerPage;
    if (props.search) {
      const response: any = props.search('', 1, recordsPerPage);
      response.then((data: any) => {
        props.setResponseData(data);
        props.setSearchData('');
        const totalRecords = data.totalRecords;
        const pageRange = getPageRange(totalRecords, recordsPerPage, props.currentPage);
        props.setCurrentRecordsDisplay(`1-${pageRange}`);
        props.setCurrentPage(1);
      });
    }
  };

  return (
    <SearchBoxWrapper>
      <StyledSearch>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          disabled={props.disabled}
          placeholder={falconeerConst.search}
          inputProps={{ 'aria-label': falconeerConst.searchLabel }}
          onChange={e => props.setSearchData(e.target.value)}
          value={props.searchedText}
        />
      </StyledSearch>
      {Boolean(props.searchedText) && (
        <CloseIconWrapper>
          <CloseIcon onClick={() => clearSearchData()} />
        </CloseIconWrapper>
      )}
      <ActionButton onClick={() => searchData()} variant='outlined'>
        Search
      </ActionButton>
    </SearchBoxWrapper>
  );
};
