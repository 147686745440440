import { listTenants } from '../api/core-graphql/core-queries';
import { CreateInvitedUserInput, Tenant, UpdateInvitedUserInput } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';

const queryDescriptors: QueryDescriptors = {
  getAll: {
    query: listTenants,
    responseAttribute: 'listTenants',
  },
};

class TenantService extends CoreAPIClient<Tenant, CreateInvitedUserInput, UpdateInvitedUserInput> {
  constructor() {
    super('Tenant', queryDescriptors);
  }
}

const tenantService = new TenantService();
Object.freeze(TenantService);

export default tenantService;
