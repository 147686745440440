import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { APP_TITLE, APP_DESCRIPTION } from './utils/constants';
import { BrowserRouter } from 'react-router-dom';
import MessageModalContextProvider from './components/dialog/MessageContextProvider';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <div>
      <title>{APP_TITLE}</title>
      <meta name='description' content={APP_DESCRIPTION} />
      <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
      <meta name='viewport' content='initial-scale=1, width=device-width' />
      <BrowserRouter>
        <MessageModalContextProvider>
          <App />
        </MessageModalContextProvider>
      </BrowserRouter>
    </div>
  </React.StrictMode>,
);

// ReactDOM.render(
//   <React.StrictMode>
//     <div>
//       <title>{APP_TITLE}</title>
//       <meta name='description' content={APP_DESCRIPTION} />
//       <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
//       <meta name='viewport' content='initial-scale=1, width=device-width' />
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </div>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
