import FalconError, { E_CONFIG_MISSING } from '../falconeer/FalconErrors';
import postLoginConfiguration from '../PostLoginConfiguration';
import preLoginConfiguration from '../PreLoginConfiguration';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const US_STATES = [
  { label: 'Alabama', value: 'AL', isActive: false },
  { label: 'Alaska', value: 'AK', isActive: false },
  { label: 'Arizona', value: 'AZ', isActive: false },
  { label: 'Arkansas', value: 'AR', isActive: false },
  { label: 'California', value: 'CA', isActive: false },
  { label: 'Colorado', value: 'CO', isActive: false },
  { label: 'Connecticut', value: 'CT', isActive: false },
  { label: 'Delaware', value: 'DE', isActive: false },
  { label: 'Florida', value: 'FL', isActive: false },
  { label: 'Georgia', value: 'GA', isActive: false },
  { label: 'Hawaii', value: 'HI', isActive: false },
  { label: 'Idaho', value: 'ID', isActive: false },
  { label: 'Illinois', value: 'IL', isActive: false },
  { label: 'Indiana', value: 'IN', isActive: false },
  { label: 'Iowa', value: 'IA', isActive: false },
  { label: 'Kansas', value: 'KS', isActive: false },
  { label: 'Kentucky', value: 'KY', isActive: false },
  { label: 'Louisiana', value: 'LA', isActive: false },
  { label: 'Maine', value: 'ME', isActive: false },
  { label: 'Maryland', value: 'MD', isActive: false },
  { label: 'Massachusetts', value: 'MA', isActive: false },
  { label: 'Michigan', value: 'MI', isActive: false },
  { label: 'Minnesota', value: 'MN', isActive: false },
  { label: 'Mississippi', value: 'MS', isActive: false },
  { label: 'Missouri', value: 'MO', isActive: false },
  { label: 'Montana', value: 'MT', isActive: false },
  { label: 'Nebraska', value: 'NE', isActive: false },
  { label: 'Nevada', value: 'NV', isActive: false },
  { label: 'New Hampshire', value: 'NH', isActive: false },
  { label: 'New Jersey', value: 'NJ', isActive: false },
  { label: 'New Mexico', value: 'NM', isActive: false },
  { label: 'New York', value: 'NY', isActive: false },
  { label: 'North Carolina', value: 'NC', isActive: false },
  { label: 'North Dakota', value: 'ND', isActive: false },
  { label: 'Ohio', value: 'OH', isActive: false },
  { label: 'Oklahoma', value: 'OK', isActive: false },
  { label: 'Oregon', value: 'OR', isActive: false },
  { label: 'Pennsylvania', value: 'PA', isActive: false },
  { label: 'Rhode Island', value: 'RI', isActive: false },
  { label: 'South Carolina', value: 'SC', isActive: false },
  { label: 'South Dakota', value: 'SD', isActive: false },
  { label: 'Tennessee', value: 'TN', isActive: false },
  { label: 'Texas', value: 'TX', isActive: false },
  { label: 'Utah', value: 'UT', isActive: false },
  { label: 'Vermont', value: 'VT', isActive: false },
  { label: 'Virginia', value: 'VA', isActive: false },
  { label: 'Washington', value: 'WA', isActive: false },
  { label: 'West Virginia', value: 'WV', isActive: false },
  { label: 'Wisconsin', value: 'WI', isActive: false },
  { label: 'Wyoming', value: 'WY', isActive: false },
];

type DataTransformer = (value: number) => number;

function convertToDate(data: any): Date | null {
  if (!data) return null;

  let date = null;
  try {
    date = data instanceof Date ? data : new Date(data);
  } catch (ex) {
    console.error('Error: unable parse the date from : ' + data, ex);
  }
  return date;
}

export function formatDateTime(data: any, timeValue: boolean = true) {
  const date = convertToDate(data);
  if (date == null) return '';

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const time = timeValue ? ` ${hours}:${minutes}` : '';
  return `${month}/${day}/${year}` + time;
}

export function formatDateTimeHour(data: any) {
  const date = convertToDate(data);
  if (date == null) return '';
  const hours = date.getHours().toString().padStart(2, '0');
  return hours;
}

export function formatDate(data: any, separator: string = '/') {
  const date = convertToDate(data);
  if (date == null) return '';

  const day = dayNames[date.getDay()];
  const dateNumber = date.getDate().toString().padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().substring(2).padStart(4, '20');
  return ` ${day}, ${month} ${dateNumber}, ${year}`;
}

export function formatDateWithDay(data: any, separator: string = '/') {
  const date = convertToDate(data);
  if (date == null) return '';
  const day = date.getDate().toString().padStart(2, '0');
  const month = monthNames[date.getMonth()];
  return `${month} ${day}`;
}

export function formatTime(data: any, includeSeconds: boolean = false) {
  const date = convertToDate(data);
  if (date == null) return '';

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  let str = `${hours}:${minutes}`;
  if (includeSeconds) {
    const seconds = date.getSeconds().toString().padStart(2, '0');
    str = `${str}:${seconds}`;
  }
  return str;
}

export function formatMonthDate(data: any) {
  const date = convertToDate(data);
  if (date == null) return '';

  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');
  return `${month} ${day}`;
}

export function formatYear(data: any) {
  const date = convertToDate(data);
  if (date == null) return '';
  const year = date.getFullYear().toString().padStart(4, '0');
  return `${year}`;
}

export function formatMonth(data: any) {
  const date = convertToDate(data);
  if (date == null) return '';

  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().padStart(4, '0');
  return `${month} ${year}`;
}

export function getMonth(data: any) {
  const date = convertToDate(data);
  if (date == null) return '';

  const month = monthNames[date.getMonth()];
  return `${month}`;
}

export function formatDay(data: any) {
  const tday = convertToDate(data);
  if (tday == null) return '';

  const day = dayNames[tday.getDay()];
  return `${day}`;
}

export function calculateAgeinYears(birthday: Date) {
  //milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
  let today = new Date(),
    //birthay has 'Dec 25 1998'
    dob = new Date(dateFormat(birthday?.toString())),
    //difference in milliseconds
    diff = today.getTime() - dob.getTime(),
    //convert milliseconds into years
    years = Math.floor(diff / 31556736000);
  return years;
}

export const isValidEmail = (email: any) => {
  // Basic email validation
  let expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return expression.test(String(email).toLowerCase());
};
export const isValidPhoneNumber = (phone: any) => {
  // Basic US phone number validation
  const phoneNumberRegex = /^\+1\d{10}$/;
  return phoneNumberRegex.test(phone);
};
export const isValidDate = (date: any) => {
  // Basic date format validation (MM/DD/YYYY)
  const dateRegex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  return dateRegex.test(date);
};

export function dateFormat(inputDate: string) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  } else {
    return '';
  }
}

export function getMonday(d: Date) {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function getSunday(d: Date) {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + 7;
  return new Date(d.setDate(diff));
}

export function capitalizeFirstLetter(input: string | null) {
  if (!input || input == null) return '';

  let text = new String(input);
  return text[0].toUpperCase() + text.slice(1);
}

//1kg = 2.2046226218lbs or pounds
export function kgToLbs(kg: number | null) {
  if (!kg || kg == null) return 0;

  const l = kg * 2.2046226218;
  let lbs = new String(l);
  lbs = (Math.round(l * 100) / 100).toString().slice(0, 3);
  return lbs;
}

export function getFullName(firstName: string, lastName: string) {
  return firstName + ' ' + lastName;
}

export function getNameLetters(name: string) {
  if (name) {
    let nameparts = name.split(' ');
    if (nameparts.length > 1) {
      return nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();
    } else {
      return nameparts[0].charAt(0).toUpperCase();
    }
  } else {
    return '';
  }
}

//cm to feet and inches
export function inchToFeetAndInches(inch: number) {
  const feet = Math.floor(inch / 12)
    .toString()
    .concat("'");
  let inches = new String(inch);
  inches = Math.round(inch % 12)
    .toString()
    .slice(0, 2)
    .concat('"');
  return feet + inches;
}

export function isEmpty(value: string) {
  return value === '' || value === undefined || value === null;
}

export async function validateEmail(text: string) {
  const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  return regex.test(text);
}

export const getDateInFormat = (filterName: string, time: string) => {
  if (filterName === 'daily') {
    return formatDateTimeHour(time);
  } else if (filterName === 'weekly') {
    return formatDay(time);
  } else if (filterName === 'monthly') {
    return formatDateWithDay(time);
  } else if (filterName === 'yearly') {
    return getMonth(time);
  } else {
    return '';
  }
};

export const getAppConfigValue = (key: string) => {
  const preLoginConfig = preLoginConfiguration.config;

  if (typeof preLoginConfig !== 'undefined' && typeof preLoginConfig[key] !== 'undefined') {
    return preLoginConfig[key];
  }

  const postLoginConfig = postLoginConfiguration.config;
  if (postLoginConfig !== null && typeof postLoginConfig[key] !== 'undefined') {
    return postLoginConfig[key];
  }
  throw new FalconError(E_CONFIG_MISSING);
};

enum DateFields {
  year = 'year',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
}
class TimezoneDate {
  private parsed: { [key in DateFields]: number };
  private weekDay: string;
  constructor(time: string) {
    const [year, month, day] = time.substring(5, 15).split('/');
    const [hour, minute, second] = time.substring(17, 25).split(':');
    this.weekDay = time.substring(0, 3);
    this.parsed = {
      year: Number(year),
      month: Number(month),
      day: Number(day),
      hour: Number(hour),
      minute: Number(minute),
      second: Number(second),
    };
  }

  public getYear() {
    return this.parsed.year;
  }

  public getMonth(): string | number {
    return this.getZeroPrefix(this.parsed.month);
  }

  public getDay(): string | number {
    return this.getZeroPrefix(this.parsed.day);
  }

  public getHour(): string | number {
    return this.getZeroPrefix(this.parsed.hour);
  }

  public getMinute(): string | number {
    return this.getZeroPrefix(this.parsed.minute);
  }

  public getSecond(): string | number {
    return this.getZeroPrefix(this.parsed.second);
  }

  public getWeekDay(): string | number {
    return this.weekDay;
  }

  private getZeroPrefix(v: number): string | number {
    return v <= 9 ? `0${v}` : v;
  }
}

export const getTimeByFilterForInTimezone = (filterName: string, date: Date, timeZone: string): string => {
  const timezoneDate = getTimezoneDate(date, timeZone);
  if (filterName === 'daily') {
    return timezoneDate.getHour() + '';
  } else if (filterName === 'weekly') {
    return timezoneDate.getWeekDay() + '';
  } else if (filterName === 'monthly') {
    const monthName = monthNames[Number(timezoneDate.getMonth()) - 1];
    return `${monthName} ${timezoneDate.getDay()}`;
  } else if (filterName === 'yearly') {
    return `${monthNames[Number(timezoneDate.getMonth()) - 1]}-${timezoneDate.getYear()}`;
  } else {
    return '';
  }
};

export const getTimezoneDate = (date: Date, timeZone: string) => {
  const formatted = date.toLocaleString('en-ZA', {
    timeZone,
    timeZoneName: 'longOffset',
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    weekday: 'short',
  });

  return new TimezoneDate(formatted);
};

export const getTimeInTimezoneForRequest = (date: Date, timeZone: string, keepTime = false): Date => {
  const formatted: string = date.toLocaleString('en-ZA', {
    timeZone,
    timeZoneName: 'longOffset',
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const dateString: string = date.toLocaleString('en-ZA').substring(0, 10).replace(/\//g, '-');

  let h: number | string = date.getHours();
  h = h < 10 ? `0${h}` : h;

  let m: number | string = date.getMinutes();
  m = m < 10 ? `0${m}` : m;

  let s: number | string = date.getSeconds();
  s = s < 10 ? `0${s}` : s;
  let time = keepTime ? `${h}:${m}:${s}` : '00:00:00';

  return new Date(`${dateString}T${time}.000` + formatted.substring(formatted.length - 6));
};

export const getDateDuration = (startDate: Date, endDate: Date) => {
  const dateStarted = new Date(startDate);
  const dateEnded = new Date(endDate);
  return ` ${dateStarted.toLocaleString('en-US', {
    month: 'short',
  })} ${dateStarted.getDate()}, ${dateStarted.getFullYear()} - ${dateEnded.toLocaleString('en-US', {
    month: 'short',
  })} ${dateEnded.getDate()}, ${dateEnded.getFullYear()}`;
};

export const measurementDataTransMap: { [key: string]: DataTransformer } = {
  exerciseduration: (value: number) => {
    return Math.round((value / 60) * 10) / 10;
  },
  sleep: (value: number) => {
    return Math.round((value / 60) * 10) / 10;
  },
};
